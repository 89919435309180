import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Global.css';
import PageNotFoundImg from '../Images/404 page not found.png'

const PageNotFound = () => {
    return (
        <div className="not-found">
            <div className="not-found-list">
                <img src={PageNotFoundImg} alt="" />
            </div>
            <div className="not-found-list">
                <h1>404</h1>
                <p>Oops! The page you're looking for doesn't exist.</p>
                <Link to="/" className="back-home">Go Back Home</Link>
            </div>
        </div>
    );
};

export default PageNotFound;
