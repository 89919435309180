import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CSS/Global.css';
import Header from './Header';

const AllCategories = () => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const fetchCategoryData = async () => {
    try {
      const response = await fetch('https://bigbullpaisewala.co.in/category/getcategory');
      const res = await response.json();
      console.log(res);
      const activeImages = res
        .filter((image) => image.status === 'Active')
        .sort((a, b) => a.position - b.position);
      setCategories(activeImages);
      setFilteredCategories(activeImages); // Initialize filtered categories
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  // Search handler
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setQuery(value);
    const filtered = categories.filter((category) =>
      category.category_name.toLowerCase().includes(value)
    );
    setFilteredCategories(filtered);
  };

  return (
    <>
      <Header />
      <div className="sub-category-page-header">
        <div
          className="back-btn-navigate"
          onClick={() => navigate(-1)}
          title="Go back"
        >
          <i className="fa-solid fa-arrow-left"></i>
        </div>
        <div className="sub-category-heading">All Categories</div>
        <div></div>
      </div>
      <div className="sub-category-page-search">
        <input
          type="search"
          placeholder="Type for search..."
          value={query}
          onChange={handleSearch}
        />
      </div>
      <div className="category-grid">
        {filteredCategories.length > 0 ? (
          filteredCategories.map((category) => (
            <div key={category.id} className="category-item">
              <Link
                to={
                  category.associatedsubcategory.length > 0
                    ? `/sub/${category.category_name}`
                    : `/${category.category_name}`
                }
                className="category-link"
              >
                <div className="category-image">
                  <img
                    src={`https://bigbullpaisewala.co.in/${category.cat_image}`}
                    alt={category.category_name}
                  />
                </div>
                <p className="category-name">{category.category_name}</p>
              </Link>
            </div>
          ))
        ) : (
          <p className="no-results">No categories found</p>
        )}
      </div>
    </>
  );
};

export default AllCategories;
