import React, { useEffect, useRef, useState } from 'react';
import '../CSS/Global.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';

const SearchPage = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]); // Store search results
    const navigate = useNavigate();
    const [storeAll, setStoreAll] = useState([]);
    const searchInputRef = useRef(null);
    const resultRef = useRef(null); // Reference for the results container

    const handleSearchChange = (event) => {
        setQuery(event.target.value);
    };

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await axios.get(`https://bigbullpaisewala.co.in/store/getstore/search`, {
                    params: { name: query },
                });

                const parsedResults = response.data
                    .filter((store) => store.status === "Active").map((store) => ({
                        ...store,
                        additionalEmails: JSON.parse(store.additionalEmails || "[]"),
                        additionalLandlines: JSON.parse(store.additionalLandlines || "[]"),
                        additionalMobiles: JSON.parse(store.additionalMobiles || "[]"),
                        additionalServices: JSON.parse(store.additionalServices || "[]"),
                        additionalWhatsapps: JSON.parse(store.additionalWhatsapps || "[]"),
                        businessDetails: JSON.parse(store.businessDetails || "{}"),
                        businessTiming: JSON.parse(store.businessTiming || "{}"),
                        contactDetails: JSON.parse(store.contactDetails || "{}"),
                        formImages: JSON.parse(store.formImages || "[]"),
                        paymentDetails: JSON.parse(store.paymentDetails || "{}"),
                        servicesDetails: JSON.parse(store.servicesDetails || "{}"),
                    }));

                setResults(parsedResults);
            } catch (error) {
                console.error("Error fetching stores:", error);
            }
        };

        if (query) {
            fetchStores();
        } else {
            setResults([]); // Clear results if query is empty
        }
    }, [query]);

    useEffect(() => {
        const fetchDataAll = async () => {
            try {
                const response = await axios.get(`https://bigbullpaisewala.co.in/store/allstorelist/allstore/adsfk1367tyh`);
                const parsedResults = response.data
                    .filter((store) => store.status === "Active") // Check for "Active" status
                    .map((store) => ({
                        ...store,
                        additionalEmails: JSON.parse(store.additionalEmails || "[]"),
                        additionalLandlines: JSON.parse(store.additionalLandlines || "[]"),
                        additionalMobiles: JSON.parse(store.additionalMobiles || "[]"),
                        additionalServices: JSON.parse(store.additionalServices || "[]"),
                        additionalWhatsapps: JSON.parse(store.additionalWhatsapps || "[]"),
                        businessDetails: JSON.parse(store.businessDetails || "{}"),
                        businessTiming: JSON.parse(store.businessTiming || "{}"),
                        contactDetails: JSON.parse(store.contactDetails || "{}"),
                        formImages: JSON.parse(store.formImages || "[]"),
                        paymentDetails: JSON.parse(store.paymentDetails || "{}"),
                        servicesDetails: JSON.parse(store.servicesDetails || "{}"),
                    }));
                setStoreAll(parsedResults);
            } catch (error) {
                console.error("Error fetching stores:", error);
            }
        };

        fetchDataAll();
    }, []);


    // Close results when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (resultRef.current && !resultRef.current.contains(event.target) && !searchInputRef.current.contains(event.target)) {
                setQuery(''); // Clear the query to hide results
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleWhatsAppClick = (storeName, mobile) => {
        const message = `Hi, I would like to inquire about ${storeName}`;
        const phoneNumber = `+91${mobile}`; // Replace with the store's WhatsApp number
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}, '_blank'`);
    };

    return (
        <>
            <Header />
            <div className="sub-category-page-header">
                <div
                    className='back-btn-navigate'
                    onClick={() => navigate(-1)}
                    title='Go back'
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </div>
                <div className='sub-category-heading'>Search Services/Listings</div>
                <div></div>
            </div>
            <div className="sub-category-page-search">
            {/* <i className="fa fa-search search-page-icon"></i> */}
                <input
                    ref={searchInputRef}
                    type="search"
                    name="search"

                    placeholder='Type for search...'
                    value={query}
                    onChange={handleSearchChange}
                />
            
            </div>

            {query && (
                <div className="search-results" ref={resultRef}>
                    {results.length > 0 ? (
                        results.map((item, index) => (
                            <div
                                key={index}
                                className="search-result-item"
                                onClick={() => navigate(
                                    `/detail-listing/${item.id}/${item.businessDetails?.businessName}`
                                )}
                            >
                                <img
                                    src={`https://bigbullpaisewala.co.in/${item.formImages[0]}`}
                                    alt={item.businessDetails.businessName}
                                    className="result-img"
                                />
                                <div className="result-info" >
                                    <h3 className="result-title">{item.businessDetails.businessName}</h3>
                                    <p className="result-description">{item.businessDetails.city}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="no-results">No results found</div>
                    )}
                </div>
            )}

            <div className="search-cards-page">
                {storeAll.map((item, index) => (
                    <div className="bigbull-card" key={index}>
                        <div className="card-body">
                            <div
                                className="card-body-img"
                                onClick={() => navigate(
                                    `/detail-listing/${item.id}/${item.businessDetails?.businessName}`
                                )}
                            >
                                <img
                                    src={`https://bigbullpaisewala.co.in/${item.formImages[0]}`}
                                    alt=""
                                />
                            </div>
                            <p
                                className="store-name"
                                onClick={() => navigate(
                                    `/detail-listing/${item.id}/${item.businessDetails?.businessName}`
                                )}
                            >
                                {item.businessDetails.businessName}
                            </p>
                            <p
                                className="store-address"
                                onClick={() => navigate(
                                    `/detail-listing/${item.id}/${item.businessDetails?.businessName}`
                                )}
                            >
                                {item.google_address}
                            </p>
                            <p
                                className="rating"
                                onClick={() => navigate(
                                    `/detail-listing/${item.id}/${item.businessDetails?.businessName}`
                                )}
                            >
                                <span className='rating-average' onClick={() => navigate(
                                    `/detail-listing/${item.id}/${item.businessDetails?.businessName}`
                                )}>5.0</span> <i className="fa-solid fa-star"></i>{' '}
                                <span className='no-of-ratings' onClick={() => navigate(
                                    `/detail-listing/${item.id}/${item.businessDetails?.businessName}`
                                )}>12 Ratings</span>
                            </p>
                            <div className="card-body-btn">
                                <Link to={`tel:${item.contactDetails.mobile}`}>
                                    <button className="contact" ><i className="fa-solid fa-phone"></i> Contact us</button>
                                </Link>
                                <button className="whatsapp" onClick={() => handleWhatsAppClick(item.businessDetails.businessName, item.contactDetails.whatsapp)}><i className="fa-brands fa-whatsapp"></i> Whatsapp</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default SearchPage;




































































// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../CSS/Global.css';
// import { useNavigate } from 'react-router-dom';

// const SearchPage = () => {
//     const [query, setQuery] = useState('');
//     const [results, setResults] = useState([]); // Store search results
//     const navigate = useNavigate()

//     const handleSearchChange = (event) => {
//         setQuery(event.target.value);
//     };

//     useEffect(() => {
//         // Fetch stores whenever the query changes
//         const fetchStores = async () => {
//             try {
//                 const response = await axios.get(https://bigbullpaisewala.co.in/store/getstore/search, {
//                     params: { name: query },
//                 });

//                 // console.log(response);
//                 const parsedResults = response.data.map((store) => {
//                     return {
//                         ...store,
//                         additionalEmails: JSON.parse(store.additionalEmails || "[]"),
//                         additionalLandlines: JSON.parse(store.additionalLandlines || "[]"),
//                         additionalMobiles: JSON.parse(store.additionalMobiles || "[]"),
//                         additionalServices: JSON.parse(store.additionalServices || "[]"),
//                         additionalWhatsapps: JSON.parse(store.additionalWhatsapps || "[]"),
//                         businessDetails: JSON.parse(store.businessDetails || "{}"),
//                         businessTiming: JSON.parse(store.businessTiming || "{}"),
//                         contactDetails: JSON.parse(store.contactDetails || "{}"),
//                         formImages: JSON.parse(store.formImages || "[]"),
//                         paymentDetails: JSON.parse(store.paymentDetails || "{}"),
//                         servicesDetails: JSON.parse(store.servicesDetails || "{}"),
//                     };
//                 });
//                 console.log(parsedResults);
//                 setResults(parsedResults);
//             } catch (error) {
//                 console.error("Error fetching stores:", error);
//             }
//         };
//         if (query) {
//             fetchStores();
//         } else {
//             setResults([]); // Clear results if query is empty
//         }
//     }, [query]);

//     return (
//         <div className="search-page">
//             <input
//                 type="text"
//                 placeholder="Search for store..."
//                 className="search-page__input"
//                 value={query}
//                 onChange={handleSearchChange}
//             />

//         </div>
//     );
// };

// export default SearchPage;