import { Link, Navigate, useNavigate } from 'react-router-dom';
import '../CSS/Global.css';
import Fruits from '../Images/fruits-main.webp'


const BigBullWorldCards = ({key,store,ratingsSummary}) => {
    // console.log(store);
    const navigate=useNavigate()
    const truncateStoreName = (name) =>
        name.length > 20 ? name.slice(0, 20) + '...' : name;

    const handleWhatsAppClick = (storeName, mobile) => {
        const message = `Hi, I would like to inquire about ${storeName}`;
        const phoneNumber = `+91${mobile}`; // Replace with the store's WhatsApp number
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}, '_blank'`);
    };
    return (
        <>
            {/* <div key={key} className="bigbullcard-list2"> */}
                <div className="bigbull-card">
                    <div className="card-body">
                        <div className="card-body-img" onClick={()=>navigate(`/detail-listing/${store.id}/${store.businessDetails.businessName}`)}>
                            <img src={`https://bigbullpaisewala.co.in/${store.formImages[0]}`} alt="" />
                        </div>
                        <p className="store-name" onClick={()=>navigate(`/detail-listing/${store.id}/${store.businessDetails.businessName}`)}>
                        {truncateStoreName(store.businessDetails.businessName)}
                        </p>
                        <p className="store-address" onClick={()=>navigate(`/detail-listing/${store.id}/${store.businessDetails.businessName}`)}>
                        {store.businessDetails && [
                                    store.businessDetails.buildingName,
                                    store.businessDetails.street,
                                    store.businessDetails.area,
                                    store.businessDetails.landmark,
                                    store.businessDetails.city,
                                    store.businessDetails.state,
                                    store.businessDetails.pincode
                                ]
                                    .filter(Boolean) // Filters out empty, null, or undefined values
                                    .join(', ')}
                        </p>
                        <p className="rating" onClick={()=>navigate(`/detail-listing/${store.id}/${store.businessDetails.businessName}`)}>
                            <span className='rating-average'>{ratingsSummary[store.user_id]?.average || 0}{' '}</span> <i className="fa-solid fa-star"></i> <span className='no-of-ratings'>{ratingsSummary[store.user_id]?.count || 0} Ratings</span>
                        </p>
                        <div className="card-body-btn">
                        <Link to={`tel:${store.contactDetails.mobile}`}>
                        <button className="contact"><i className="fa-solid fa-phone"></i> Contact us</button>
                        </Link>
                        <button className="whatsapp" onClick={() => handleWhatsAppClick(store.businessDetails.businessName, store.contactDetails.whatsapp)}><i className="fa-brands fa-whatsapp"></i> Whatsapp</button>
                        </div>
                    </div>
                </div>
                
            {/* </div> */}
        </>
    );
};

export default BigBullWorldCards;