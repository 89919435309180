// import React, { useEffect, useState } from "react";
// import {
//     Page,
//     Text,
//     View,
//     Document,
//     StyleSheet,
//     PDFViewer,
//     Image,
//     pdf,
// } from "@react-pdf/renderer";
// import Logo from '../Images/160X60-BIGBULL-PAISEWALA.png';
// import { useNavigate, useParams } from "react-router-dom";

// // Define styles for the PDF
// const styles = StyleSheet.create({
//     page: {
//         padding: 30,
//         fontFamily: "Helvetica",
//     },
//     header: {
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//         marginBottom: 20,
//     },

//     logo: {
//         width: 100,
//         marginBottom: 5,
//     },
//     companyDetails: {
//         textAlign: "right",
//     },
//     section: {
//         marginBottom: 20,
//     },
//     table: {
//         display: "table",
//         width: "auto",
//         marginBottom: 20,
//     },
//     tableRow: {
//         flexDirection: "row",
//         borderBottom: 1, // Border bottom for each row
//         borderColor: "#bfbfbf",
//         paddingVertical: 5,  // Ensure rows have vertical padding
//     },
//     tableCol: {
//         flex: 1,
//         padding: 5,
//         fontSize: 12,
//         textAlign: "center",
//         borderStyle: "solid", // Optional, if you want a thin line but no complete borders
//         borderWidth: 0, // Remove the borders
//     },
//     tableHeader: {
//         fontWeight: "bold",
//         backgroundColor: "#F91919",
//         fontSize: 14,
//         textAlign: "center",
//         color: "#fff",
//     },
//     charges: {
//         alignSelf: "flex-end",
//         marginTop: 10,
//         width: '30%'
//     },
//     chargeItem: {
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//         marginBottom: 5,
//     },
//     chargeItemTotal: {
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//         marginBottom: 5,
//         borderTop: "1px",
//         borderTopColor: "#333",
//         width: '100%',
//         marginHorizontal: 'auto'
//     },
//     terms: {
//         marginTop: 20,
//     },
//     termsText: {
//         marginBottom: 5,
//         fontSize: 12,
//     },
// });


// let fetchOrderData = async () => {
//     try {
//         let response = await fetch(`https://bigbullpaisewala.co.in/ads/allsingle/${id}`)
//         let res = await response.json();
//         console.log(res);
//         SetOrder(res)

//     } catch (error) {
//         console.log(error);
//     }
// }

// useEffect(() => {
//     fetchOrderData();
// }, [])


// // Create the Invoice document
// const InvoiceDocument = () => (
//     <Document>
//         <Page size="A4" style={styles.page}>
//             {/* Header Section */}
//             <View style={styles.header}>
//                 <View>
//                     <Text style={{ fontSize: 16, marginBottom: 5, color: "red" }}>INVOICE</Text>
//                     <Image
//                         style={styles.logo}
//                         src={Logo} // Replace with your logo path
//                     />
//                 </View>
//                 <View style={styles.companyDetails}>
//                     <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 5 }}>Big Bull Business Solution Private Limited</Text>
//                     <Text style={{ fontSize: 12, marginBottom: 5 }}>Phone: +91 78877 69550</Text>
//                     <Text style={{ fontSize: 12, marginBottom: 5 }}>Email: info@bigbullpaisewala.com</Text>
//                     <Text style={{ fontSize: 12, marginBottom: 5 }}>Website: www.bigbullpaisewala.com</Text>
//                 </View>
//             </View>

//             {/* Details Section */}
//             <View style={styles.section}>
//                 <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
//                     <View style={{ flex: 1 }}>
//                         <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 5 }}>Invoice details:</Text>
//                         <Text style={{ fontSize: 12, marginBottom: 5 }}>Invoice #: 00{order.id}</Text>
//                         <Text style={{ fontSize: 12, marginBottom: 5 }}>Date: 30/11/2024</Text>
//                     </View>
//                     <View style={{ textAlign: "right", flex: 1 }}>
//                         <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 5 }}>Bill to:</Text>
//                         <Text style={{ fontSize: 12, marginBottom: 5 }}>Customer Name</Text>
//                     </View>
//                 </View>
//             </View>

//             {/* Table Section */}
//             <View style={styles.table}>
//                 <View style={[styles.tableRow, styles.tableHeader]}>
//                     <Text style={styles.tableCol}>Item Name</Text>
//                     <Text style={styles.tableCol}>Description</Text>
//                     <Text style={styles.tableCol}>Quantity</Text>
//                     <Text style={styles.tableCol}>Rate</Text>
//                     <Text style={styles.tableCol}>Amount</Text>
//                 </View>
//                 <View style={styles.tableRow}>
//                     <Text style={styles.tableCol}>Item 1</Text>
//                     <Text style={styles.tableCol}>Description 1</Text>
//                     <Text style={styles.tableCol}>2</Text>
//                     <Text style={styles.tableCol}>Rs. 500</Text>
//                     <Text style={styles.tableCol}>Rs. 1000</Text>
//                 </View>
//                 <View style={styles.tableRow}>
//                     <Text style={styles.tableCol}>Item 2</Text>
//                     <Text style={styles.tableCol}>Description 2</Text>
//                     <Text style={styles.tableCol}>1</Text>
//                     <Text style={styles.tableCol}>Rs. 800</Text>
//                     <Text style={styles.tableCol}>Rs. 800</Text>
//                 </View>
//             </View>

//             {/* Charges Section */}
//             <View style={styles.charges}>
//                 <View style={styles.chargeItem}>
//                     <Text style={{ fontSize: 12, flex: 1 }}>Amount:</Text>
//                     <Text style={{ fontSize: 12 }}>Rs. 1900</Text>
//                 </View>
//                 <View style={styles.chargeItem}>
//                     <Text style={{ fontSize: 12, flex: 1 }}>Delivery Charge:</Text>
//                     <Text style={{ fontSize: 12 }}>Rs. 50</Text>
//                 </View>
//                 <View style={styles.chargeItem}>
//                     <Text style={{ fontSize: 12, flex: 1 }}>Discount:</Text>
//                     <Text style={{ fontSize: 12 }}>Rs. 50</Text>
//                 </View>
//                 <View style={styles.chargeItem}>
//                     <Text style={{ fontSize: 12, flex: 1 }}>Tax Charge:</Text>
//                     <Text style={{ fontSize: 12 }}>Rs. 100</Text>
//                 </View>
//                 <View style={styles.chargeItemTotal}>
//                     <Text style={{ fontSize: 12, fontWeight: "bold", flex: 1, marginTop: 5 }}>Total Paid:</Text>
//                     <Text style={{ fontSize: 12, fontWeight: "bold", marginTop: 5 }}>Rs. 2000</Text>
//                 </View>
//             </View>

//             {/* Terms Section */}
//             <View style={styles.terms}>
//                 <Text style={{ fontWeight: "bold", fontSize: 14, marginBottom: 5 }}>Terms:</Text>
//                 <Text style={styles.termsText}>1. Payment is due within 7 days.</Text>
//                 <Text style={styles.termsText}>2. Late payments may incur additional charges.</Text>
//                 <Text style={styles.termsText}>3. Delivery fees are non-refundable.</Text>
//                 <Text style={styles.termsText}>4. Tax rates may vary by region.</Text>
//                 <Text style={styles.termsText}>5. Contact us for any discrepancies.</Text>
//             </View>
//         </Page>
//     </Document>
// );

// // Main Component with Viewer
// const Invoice = () => {
//     const [isMobile, setIsMobile] = useState(false);
//     const navigate = useNavigate()
//     const { id } = useParams()

//     useEffect(() => {
//         const handleResize = () => setIsMobile(window.innerWidth <= 768);
//         handleResize(); // Check initial screen size
//         window.addEventListener("resize", handleResize);

//         // Auto-download on mobile screens
//         if (window.innerWidth <= 768) {
//             handleDownload();
//             navigate(-1)
//         }

//         return () => window.removeEventListener("resize", handleResize);
//     }, []);

//     const handleDownload = async () => {
//         const blob = await pdf(<InvoiceDocument />).toBlob();
//         const url = URL.createObjectURL(blob);
//         const link = document.createElement("a");
//         link.href = url;
//         link.download = "invoice.pdf";
//         link.click();
//     };




//     return (
//         <div>

//             <PDFViewer width="100%" height="800px">
//                 <InvoiceDocument />
//             </PDFViewer>

//         </div>
//     );
// };


// export default Invoice;


import React, { useEffect, useState } from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFViewer,
    Image,
    pdf,
} from "@react-pdf/renderer";
import Logo from '../Images/160X60-BIGBULL-PAISEWALA.png';
import { useNavigate, useParams } from "react-router-dom";

// Define styles for the PDF
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: "Helvetica",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 20,
    },
    logo: {
        width: 100,
        marginBottom: 5,
    },
    companyDetails: {
        textAlign: "right",
    },
    section: {
        marginBottom: 20,
    },
    table: {
        display: "table",
        width: "auto",
        marginBottom: 20,
    },
    tableRow: {
        flexDirection: "row",
        borderBottom: 1,
        borderColor: "#bfbfbf",
        paddingVertical: 5,
    },
    tableCol: {
        flex: 1,
        padding: 5,
        fontSize: 12,
        textAlign: "center",
    },
    tableHeader: {
        fontWeight: "bold",
        backgroundColor: "#F91919",
        fontSize: 14,
        textAlign: "center",
        color: "#fff",
    },
    charges: {
        alignSelf: "flex-end",
        marginTop: 10,
        width: '30%',
    },
    chargeItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 5,
    },
    chargeItemTotal: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 5,
        borderTop: "1px",
        borderTopColor: "#333",
        width: '100%',
        marginHorizontal: 'auto',
    },
    terms: {
        marginTop: 20,
    },
    termsText: {
        marginBottom: 5,
        fontSize: 12,
    },
});

const InvoiceDocument = ({ order ,id}) => (
 
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View>
                    <Text style={{ fontSize: 16, marginBottom: 5, color: "red" }}>INVOICE</Text>
                    <Image style={styles.logo} src={Logo} />
                </View>
                <View style={styles.companyDetails}>
                    <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 5 }}>Big Bull Business Solutions Private Limited</Text>
                    <Text style={{ fontSize: 12, marginBottom: 5 }}>Phone: +91 78877 69550</Text>
                    <Text style={{ fontSize: 12, marginBottom: 5 }}>Email: info@bigbullpaisewala.com</Text>
                    <Text style={{ fontSize: 12, marginBottom: 5 }}>Website: www.bigbullpaisewala.com</Text>
                </View>
            </View>

            <View style={styles.section}>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flex: 1 }}>
                        <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 5 }}>Invoice details:</Text>
                        <Text style={{ fontSize: 12, marginBottom: 5 }}>Invoice #: 00{id}</Text>
                        <Text style={{ fontSize: 12, marginBottom: 5 }}>Date: {order?.createdAt || "30/11/2024"}</Text>
                    </View>
                    <View style={{ textAlign: "right", flex: 1 }}>
                        <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 5 }}>Bill to:</Text>
                        <Text style={{ fontSize: 12, marginBottom: 5 }}>{order?.user_name || "Customer Name"}</Text>
                    </View>
                </View>
            </View>

            {/* Other sections here */}
            <View style={styles.table}>
                <View style={[styles.tableRow, styles.tableHeader]}>
                    <Text style={styles.tableCol}>Item Name</Text>
                    <Text style={styles.tableCol}>Description</Text>
                    <Text style={styles.tableCol}>Quantity</Text>
                    <Text style={styles.tableCol}>Rate</Text>
                    <Text style={styles.tableCol}>Amount</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={styles.tableCol}>Ad</Text>
                    <Text style={styles.tableCol}>Banner Ads</Text>
                    <Text style={styles.tableCol}>1</Text>
                    <Text style={styles.tableCol}>Rs.{order.plan.price}</Text>
                    <Text style={styles.tableCol}>Rs. {order.plan.price}</Text>
                </View>
                
            </View>
            {/* Charges Section */}
            <View style={styles.charges}>
                <View style={styles.chargeItem}>
                    <Text style={{ fontSize: 12, flex: 1 }}>Amount:</Text>
                    <Text style={{ fontSize: 12 }}>Rs. {order.plan.price}</Text>
                </View>
                {/* <View style={styles.chargeItem}>
                    <Text style={{ fontSize: 12, flex: 1 }}>Delivery Charge:</Text>
                    <Text style={{ fontSize: 12 }}>Rs. 50</Text>
                </View>
                <View style={styles.chargeItem}>
                    <Text style={{ fontSize: 12, flex: 1 }}>Discount:</Text>
                    <Text style={{ fontSize: 12 }}>Rs. 50</Text>
                </View>
                <View style={styles.chargeItem}>
                    <Text style={{ fontSize: 12, flex: 1 }}>Tax Charge:</Text>
                    <Text style={{ fontSize: 12 }}>Rs. 100</Text>
                </View> */}
                <View style={styles.chargeItemTotal}>
                    <Text style={{ fontSize: 12, fontWeight: "bold", flex: 1, marginTop: 5 }}>Total Paid:</Text>
                    <Text style={{ fontSize: 12, fontWeight: "bold", marginTop: 5 }}>Rs. {order.plan.price}</Text>
                </View>
            </View>
            {/* Terms Section */}
            <View style={styles.terms}>
                <Text style={{ fontWeight: "bold", fontSize: 14, marginBottom: 5 }}>Terms:</Text>
                <Text style={styles.termsText}>1. Payment is due within 7 days.</Text>
                <Text style={styles.termsText}>2. Late payments may incur additional charges.</Text>
                <Text style={styles.termsText}>3. Delivery fees are non-refundable.</Text>
                <Text style={styles.termsText}>4. Tax rates may vary by region.</Text>
                <Text style={styles.termsText}>5. Contact us for any discrepancies.</Text>
            </View>
        </Page>
    </Document>
);

const Invoice = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [order, setOrder] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const convertToIST = (dateString) => {
        const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "long",
            day: "numeric",
           
        };
    
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-IN", options).format(date);
    };
    
    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                const response = await fetch(`https://bigbullpaisewala.co.in/ads/allsingle/${id}`);
                const data = await response.json();
    
                // Parse the `plan` field from string to an object
                if (data.plan) {
                    data.plan = JSON.parse(data.plan);
                }
    
                console.log(data);
                setOrder(data);
                data.start = convertToIST(data.start);
                data.end = convertToIST(data.end);
                data.createdAt = convertToIST(data.createdAt);
                data.updatedAt = convertToIST(data.updatedAt);
                if (window.innerWidth <= 768) {
                    handleDownload(data);
                    navigate(-1); // Navigate back after download
                }
            } catch (error) {
                console.error("Failed to fetch order data", error);
            }
        };
    
        fetchOrderData();
    }, [id, navigate]);
    

    const handleDownload = async (data) => {
        const blob = await pdf(<InvoiceDocument order={data} />).toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "invoice.pdf";
        link.click();
    };

    return (
        <div>
            {order && !isMobile && (
                <PDFViewer width="100%" height="800px">
                    <InvoiceDocument order={order} id={id}/>
                </PDFViewer>
            )}
        </div>
    );
};

export default Invoice;
