import React, {useRef , useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../CSS/Global.css';
import Header from './Header';

const SubCategoriesPage = () => {
    const navigate = useNavigate();
    const searchInputRef = useRef(null);

    const [categories, setCategories] = useState([]);
    
      const fetchCategoryData = async () => {
        try {
          const response = await fetch('https://bigbullpaisewala.co.in/category/getcategory');
          const res = await response.json();
          console.log(res);
          const activeImages = res
            .filter((image) => image.status === 'Active')
            .sort((a, b) => a.position - b.position);
          setCategories(activeImages);
        } catch (error) {
          console.log(error);
        }
      };
    
      useEffect(() => {
        fetchCategoryData();
      }, []);

    useEffect(() => {
        // Automatically focus the input field when the component mounts
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    return (
        <>
            <Header />
            <div className="sub-category-page-header">
                <div
                    className='back-btn-navigate'
                    onClick={() => navigate(-1)}
                    title='Go back'
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </div>
                <div className='sub-category-heading'>Sub category</div>
                <div></div>
            </div>
            <div className="sub-category-page-search">
                <input
                    ref={searchInputRef} 
                    type="search"
                    placeholder='Type for search...'
                />
            </div>

            <div className="category-grid">
                  {categories.map((category) => (
                    <div key={category.id} className="category-item">
                      <Link
                        to={category.associatedsubcategory.length > 0 ? `/sub/${category.category_name}` : `/${category.category_name}`}
                        className="category-link"
                      >
                        <div className="category-image">
                          <img
                            src={`https://bigbullpaisewala.co.in/${category.cat_image}`}
                            alt={category.category_name}
                          />
                        </div>
                        <p className="category-name">{category.category_name}</p>
                      </Link>
                    </div>
                  ))}
                </div>
        </>
    );
};

export default SubCategoriesPage;
