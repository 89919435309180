import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';
import { useNavigate, useParams } from 'react-router-dom';
import NoProductFound from './NoProduct';
import { Text } from '@chakra-ui/react';
import Header from './Header';

const Grocery = () => {
  // Dummy store data
  // const stores = [
  //   { id: 1, name: 'Innovatech Systems Pvt. Ltd.', rating: 4.3, ratingsCount: 284, address: '42 Tech Park, Electronic City, Bengaluru, Karnataka 560100', img: 'https://picsum.photos/200?random=1' },
  //   { id: 2, name: 'TechPulse Pvt. Ltd.', rating: 4.1, ratingsCount: 198, address: '10 Avenue Street, Whitefield, Bengaluru, Karnataka 560066', img: 'https://picsum.photos/200?random=2' },
  //   { id: 3, name: 'GroMart Pvt. Ltd.', rating: 4.7, ratingsCount: 352, address: '9th Block, HSR Layout, Bengaluru, Karnataka 560102', img: 'https://picsum.photos/200?random=3' },
  //   { id: 4, name: 'RetailTech Solutions', rating: 4.5, ratingsCount: 225, address: 'IT Hub, Marathahalli, Bengaluru, Karnataka 560037', img: 'https://picsum.photos/200?random=4' },
  //   { id: 5, name: 'Supernova Groceries', rating: 4.2, ratingsCount: 340, address: '14 MG Road, Ulsoor, Bengaluru, Karnataka 560008', img: 'https://picsum.photos/200?random=5' },
  //   { id: 6, name: 'ShopEasy Pvt. Ltd.', rating: 4.0, ratingsCount: 265, address: '8th Cross, Indiranagar, Bengaluru, Karnataka 560038', img: 'https://picsum.photos/200?random=6' },
  //   { id: 7, name: 'AgroWorld Pvt. Ltd.', rating: 4.6, ratingsCount: 312, address: 'Near Forum Mall, Koramangala, Bengaluru, Karnataka 560095', img: 'https://picsum.photos/200?random=7' },
  //   { id: 8, name: 'TechyMart Pvt. Ltd.', rating: 4.3, ratingsCount: 290, address: '3rd Main Road, Jayanagar, Bengaluru, Karnataka 560041', img: 'https://picsum.photos/200?random=8' },
  //   { id: 9, name: 'SuperTech Retailers', rating: 4.8, ratingsCount: 410, address: '12th Avenue, Yelahanka, Bengaluru, Karnataka 560064', img: 'https://picsum.photos/200?random=9' },
  //   { id: 10, name: 'Retailify Pvt. Ltd.', rating: 4.4, ratingsCount: 150, address: 'Bellandur Tech Park, Bengaluru, Karnataka 560103', img: 'https://picsum.photos/200?random=10' }
  // ];
  const { id } = useParams();
  const [stores, setStores] = useState([])
  console.log(id)
  const navigate = useNavigate("")
  const [ratingsSummary, setRatingsSummary] = useState({});

  const fetchDataAllCategory = async () => {
    try {
      const response = await fetch(`https://bigbullpaisewala.co.in/store/getstore/category/${id}`);
      const res = await response.json();
      console.log(res);
  
      // Filter stores with "Active" status and parse the data
      const activeStores = res
        .filter((item) => item.status === "Active")
        .map((store) => ({
          ...store,
          additionalEmails: JSON.parse(store.additionalEmails || "[]"),
          additionalLandlines: JSON.parse(store.additionalLandlines || "[]"),
          additionalMobiles: JSON.parse(store.additionalMobiles || "[]"),
          additionalServices: JSON.parse(store.additionalServices || "[]"),
          additionalWhatsapps: JSON.parse(store.additionalWhatsapps || "[]"),
          businessDetails: JSON.parse(store.businessDetails || "{}"),
          businessTiming: JSON.parse(store.businessTiming || "{}"),
          contactDetails: JSON.parse(store.contactDetails || "{}"),
          formImages: JSON.parse(store.formImages || "[]"),
          paymentDetails: JSON.parse(store.paymentDetails || "{}"),
          servicesDetails: JSON.parse(store.servicesDetails || "{}"),
        }));
  
      // Set the parsed and filtered data to state
      setStores(activeStores);
  
      console.log(activeStores); // Log the parsed stores to verify
    } catch (error) {
      console.log(error);
    }
  };
  
  // Function to handle contact button click
  const handleContactClick = (call) => {
    // Redirect to the call log
    window.location.href = call; // Replace with actual phone number
  };

  // Function to handle WhatsApp button click
  const handleWhatsAppClick = (storeName, whatsapp) => {
    const message = `Hi, I would like to inquire about ${storeName}`;
    const phoneNumber = whatsapp; // Replace with the store's WhatsApp number
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };

  // Helper function to truncate store names
  const truncateName = (name) => {
    return name.length > 20 ? `${name.substring(0, 20)}...` : name;
  };

  const fetchRatingsSummary = async (storeId) => {
    try {
      const response = await fetch(`https://bigbullpaisewala.co.in/rating/summary/${storeId}`);
      const res = await response.json();
      if (!res.isError) {
        setRatingsSummary((prevSummary) => ({
          ...prevSummary,
          [storeId]: {
            count: res.count || 0,
            average: res.average || 0,
          },
        }));
      }
    } catch (error) {
      console.error('Failed to fetch ratings summary:', error);
    }
  };

  useEffect(() => {
    stores.forEach((store) => {
      fetchRatingsSummary(store.user_id);
    });
  }, [stores]);


  useEffect(() => {
    fetchDataAllCategory();
  }, [])

 console.log(stores);

  return (
    <>
      <Header />
      {
        stores.length >0 ?
          <div className="category-store">
            {stores.map(store => (
              <div key={store.id} onClick={() => navigate(`/detail-listing/${store.id}/${store.businessDetails.businessName}`)} className="category-store-card">
                <div className="category-store-card-image">
                  <img src={`https://bigbullpaisewala.co.in/${store.formImages[0]}`} alt={store.businessDetails.businessName} />
                </div>

                <div className="category-store-info">
                  <div className="category-store-card-name">
                    <p>{truncateName(store.businessDetails.businessName)}</p>
                  </div>

                  <div className="category-store-card-rating">
                    <p className="review">{ratingsSummary[store.user_id]?.average || 0}{' '} <i className="fa-solid fa-star"></i></p>
                    <p className="reviewnumber">{ratingsSummary[store.user_id]?.count || 0} Ratings</p>
                    {store && store.verified === true ? <Text color="green.500" fontSize='sm'>
                      <i className="fa-solid fa-circle-check"></i> Verified
                    </Text> : null}

                  </div>

                  <div className="category-store-card-address">
                    <p>
                      {[
                        store.businessDetails.buildingName,
                        store.businessDetails.street,
                        store.businessDetails.area,
                        store.businessDetails.landmark,
                        store.businessDetails.city,
                        store.businessDetails.state,
                        store.businessDetails.pincode
                      ]
                        .filter(Boolean) // Filters out empty, null, or undefined values
                        .join(', ')}
                    </p>
                  </div>


                  <div className="category-store-card-buttons">
                    <button className="contact-button" onClick={() => handleContactClick(store.businessDetails.mobile)}>
                      <i className="fa-solid fa-phone"></i> Contact
                    </button>
                    <button className="whatsapp-button" onClick={() => handleWhatsAppClick(store.businessDetails.businessName, store.contactDetails.whatsapp)}>
                      <i className="fa-brands fa-whatsapp"></i> WhatsApp
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>:<NoProductFound name={id} />
      }
    </>
  );
};

export default Grocery;
