import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';
import { useNavigate } from 'react-router-dom';

const CategoryCol = () => {
    const [data, setData] = useState([]);
    const navigate=useNavigate(); 

    const fetchData = async () => {
        try {
            // Fetch data from the server
            const response = await fetch(`https://bigbullpaisewala.co.in/special-category/getcategory/bigbullpaisewalame1876`);
            const res = await response.json();
    
            // Log the entire response to debug
            console.log("Full Response:", res);
    
            // Safely parse the store_list
            if (res && res.store_list) {
                const parsedStoreList = JSON.parse(res.store_list);
                console.log("Parsed Store List:", parsedStoreList);
    
                // Set parsed data to state
                setData(parsedStoreList);
            } else {
                console.error("store_list is undefined or null");
            }
        } catch (error) {
            console.error("Error fetching or parsing data:", error);
        }
    };
    

    const handleClick = (subcategory, category) => {
        console.log(category,)
        if (subcategory.length > 0) {
            navigate(`/sub/${category}`)
        } else {
            navigate(`${category}`)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='categorycol'>
            {/* First Column */}
            {
                data.map((ele, idx) => {
                    return (
                        <div className='category-column' key={idx}>
                            <div className='category-col-heading'>
                                {ele.store_list && ele.store_list.length > 0 ? <p>{ele.category}</p> : null}
                            </div>
                            <div className='category-col-body'>
                                {
                                    ele.store_list && ele.store_list.length > 0 ? (
                                        ele.store_list.map((cat, idx) => {
                                            return (
                                                <div className='category-col-card' key={idx}
                                                    onClick={() => handleClick(cat.associatedsubcategory, cat.category_name)}
                                                >
                                                    <div className='category-col-img'>
                                                        <img src={`https://bigbullpaisewala.co.in/${cat.cat_image}`} alt='Manufacturers' />
                                                    </div>
                                                    <div className='category-col-name'>
                                                        <p>{cat.category_name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default CategoryCol;
