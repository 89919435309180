import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';
import ad from "../Images/ad.png"
import { Spinner, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Ad from '../Images/common Ad Home.gif'

const AdsComponent = () => {
    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(1); // Step state to control the modal steps
    const [selectedPlan, setSelectedPlan] = useState('');
    const [error, setError] = useState('');
    const [adTitle, setAdTitle] = useState('');
    const [adDescription, setAdDescription] = useState('');
    const [website, setWebsite] = useState('');
    const [mobile, setMobile] = useState('');
    const [image, setImage] = useState(null);
    const [pricing, setPricing] = useState([])
    const [remarks, setRemarks] = useState();
    const [planSelected, setPlanSelected] = useState('')
    const login = JSON.parse(localStorage.getItem("login")) || ""
    const toast = useToast()
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);    

    const handleCreateAdsClick = () => {
        setShowModal(true);
        setError('');
        setStep(1); // Reset to the first step
    };

    const handlePlanChange = (plan, full) => {
        setSelectedPlan(plan);
        setPlanSelected(full);
        setError('');
    };

    const handleNextStep = () => {
        if (!selectedPlan) {
            setError('Please select a plan before proceeding.');
        } else {
            setError('');
            setStep(2); // Proceed to the next step
        }
    };
    console.log(planSelected)

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    };

    const handleClick = async (log) => {
        setLoad(true)
        if (!adTitle || !adDescription || !mobile) {
            setError('Please fill in all required fields.');
            setLoad(false)
        } else {
            try {
                let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

                // Load the Razorpay SDK
                let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

                if (!res) {
                    alert("Razorpay SDK Failed to load. Are you online?");
                    return;
                }

                // Define payment details
                let paymentDetails = {

                    user_id: login.permanent_ID,
                    user_phone: login.name,
                    transaction_id: orderId,
                    amount: parseInt(planSelected.price) * 100,
                    currency: "INR",
                    payment_capture: 1,
                    payment_mode: "online",
                };

                // Create order options
                let options = {
                    key: "rzp_live_Xe3FsoiFgJrvDM",
                    currency: paymentDetails.currency,
                    amount: paymentDetails.amount,
                    name: "Big Bull Paisewala",
                    description: "Transaction",
                    handler: async function (response) {

                        if (response.razorpay_payment_id) {
                            // Payment was successful
                            paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
                            paymentDetails.status = 'Paid';
                            let result = await axios.post(`https://bigbullpaisewala.co.in/pay-all`, paymentDetails);

                            if (!result.data.isError) {
                                // Redirect to the success page
                                //   checkPaymentStatus();
                                handleCreateAd(result.data.payments)
                                navigate("/")
                                setLoad(false);
                                // window.location.href = `/delivery/${orderId}`; // Replace "/success-page" with the URL of your success page
                            } else {
                                alert("Server error. Are You Online");
                                setLoad(false);
                                //   window.location.href = "/error-page"; // Redirect to an error page
                            }
                        } else {
                            // Handle payment failure or cancellation

                            paymentDetails.status = 'Failed';
                            const payment = "Failed"
                            await axios.post(`https://bigbullpaisewala.co.in/pay-all`, paymentDetails);
                            handleCreateAd(payment)
                            // window.location.href = "/error-page"; // Redirect to an error page
                        }
                    },
                    prefill: {
                        email: "bigbullbusinesssolutionspvtltd@gmail.com",
                        contact: "7757855388"
                    },
                    notes: {
                        address: "Razorpay Corporate Office"
                    },
                    theme: {
                        color: "#f8402a" 
                    },
                    modal: {
                        ondismiss: function () {
                            // Handle Razorpay modal close
                            setLoad(false);
                        },
                    },
                };

                // Initialize Razorpay payment object and open checkout
                let paymentObject = new window.Razorpay(options);
                paymentObject.on('payment.failed', function (response) {
                    paymentDetails.status = 'Failed';
                    const payment = "Failed"
                    axios.post(`https://bigbullpaisewala.co.in/pay-all`, paymentDetails)
                        .then(() => {
                            navigate("/") // Redirect to an error page
                            handleCreateAd(payment)
                            
                        }).catch(error => {
                            navigate("/") // Redirect to an error page
                            setShowModal(false); // Close the modal on success
                            setLoad(false)
                        });
                });

                paymentObject.open();

            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleCreateAd = async (dataget) => {


        // Create a FormData object to handle both text fields and file uploads
        const formData = new FormData();
        formData.append('title', adTitle);
        formData.append('user_id', login.permanent_ID);
        formData.append('user_name', login.name);
        formData.append('description', adDescription);
        formData.append('mobile', mobile);
        formData.append('plan', JSON.stringify(planSelected)); // Include the selected plan
        formData.append("money_status", dataget)
        if (website) {
            formData.append('website', website); // Add the website if provided
        }
        if (image) {
            formData.append('banner', image); // Add the image file if selected
        }

        console.log(formData)

        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/ads/nextpageads`, {
                method: "POST",
                body: formData // Send the form data
            });

            const result = await response.json();

            if (response.ok) {
                toast({
                    description: "Ads Created Successfully",
                    status: 'success',
                    position: "top-center",
                    duration: 5000,
                    isClosable: true,
                })
                setShowModal(false); // Close the modal on success
                setLoad(false)
            } else {
                setError(result.message || 'Failed to create ad');
                setShowModal(false); // Close the modal on success
                setLoad(false)
            }
        } catch (error) {
            console.error('Error creating ad:', error);
            setError('An error occurred while creating the ad.');
            setShowModal(false); // Close the modal on success
            setLoad(false)
        }

    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const fetchBannerAds = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/adspricing/all/pricingall`)
            const res = await response.json();
            console.log(res);
            // setSelectedPlan()
            setPricing(res);
        } catch (error) {

        }
    }

    console.log(planSelected)

    useEffect(() => {
        fetchBannerAds();
    }, [])
    return (
        <div className='advertise-main' onClick={handleCreateAdsClick}>
            <img src={Ad} alt="" />
            {/* <div className='advertise-left-column'>
                <img src={ad} />
            </div>
            <div className='advertise-right-column'>
                <h3>Branding awareness you can count on</h3>
                <p>Make a lasting impression</p>
                <button onClick={handleCreateAdsClick}><i className="fa-solid fa-rectangle-ad"></i>Create Your Ads</button>
            </div> */}

            {showModal && (
                <div className='ads-modal'>
                    <div className='ads-modal-content'>
                        <button className="modal-close" onClick={handleCloseModal}>&times;</button>
                        {step === 1 ? (
                            <>
                                <h2>Choose Your Plan</h2>
                               
                                <div className='plans'>
                                    {pricing.map((plan, index) => (
                                        <div
                                            key={index}
                                            className={`plan-card ${selectedPlan === plan.id ? 'selected' : ''}`} // Use the plan's name dynamically
                                            onClick={() => handlePlanChange(plan.id, plan)}
                                        >
                                            <h3>{plan.validity} days</h3> {/* Assuming `plan.duration` contains the duration like '1 Month' */}
                                            <p className='banner-ads'>({plan.plan_name})</p>
                                            <div className='adv-price'>
                                                <p>₹ {plan.price}</p> {/* Assuming `plan.price` contains the price */}
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className={`plan-card ${selectedPlan === 'Premium' ? 'selected' : ''}`} onClick={() => handlePlanChange('Premium')}>
                                        <h3>1 Month</h3>
                                        <p className='banner-ads'>(Banner ads)</p>
                                        <div className='adv-price'>
                                            <p>₹ 2500</p>
                                        </div>
                                    </div>

                                    <div className={`plan-card ${selectedPlan === 'Standard' ? 'selected' : ''}`} onClick={() => handlePlanChange('Standard')}>
                                        <h3>15 Days</h3>
                                        <p className='banner-ads'>(Banner ads)</p>
                                        <div className='adv-price'>
                                            <p>₹ 1500</p>
                                        </div>
                                    </div>

                                    <div className={`plan-card ${selectedPlan === 'Basic' ? 'selected' : ''}`} onClick={() => handlePlanChange('Basic')}>
                                        <h3>7 Days</h3>
                                        <p className='banner-ads'>(Banner ads)</p>
                                        <div className='adv-price'>
                                            <p>₹ 900</p>
                                        </div>
                                    </div> */}
                                </div>

                                {error && <p className="error-message">{error}</p>}

                                <div className="btn-ads">
                                    <button onClick={handleNextStep}>Next</button>
                                    <button onClick={handleCloseModal} className="btn-cancel">
                                        Close
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <h2>Create Your Ad</h2>
                                <label>Ad Title <span className='ads-step2-bracket'>(Required)</span></label>
                                <input
                                    type="text"
                                    value={adTitle}
                                    onChange={(e) => setAdTitle(e.target.value)}
                                    required
                                    placeholder="Enter your ad title"
                                />
                                <label>Upload Image <span className='ads-step2-bracket'>(Optional)</span></label>
                                <input
                                    type="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                />
                                <label>Description <span className='ads-step2-bracket'>(Required)</span></label>
                                <textarea
                                    value={adDescription}
                                    onChange={(e) => setAdDescription(e.target.value)}
                                    required
                                    placeholder="Enter ad description"
                                ></textarea>
                                <label>Do you want to add a website? <span className='ads-step2-bracket'>(Optional)</span></label>
                                <input
                                    type="url"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                    placeholder="Enter website URL"
                                />
                                <label>Mobile Number <span className='ads-step2-bracket'>(Required)</span></label>
                                <input
                                    type="tel"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    required
                                    placeholder="Enter your mobile number"
                                />

                                {error && <p className="error-message">{error}</p>}

                                <div className="btn-ads">
                                    {
                                        load ? <button><Spinner /></button> : <button onClick={handleClick}>Create and Pay</button>
                                    }

                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdsComponent;
