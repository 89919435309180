
import React, { useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Heading,
    VStack,
    useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SetResetPassword = () => {
    const [step, setStep] = useState(2); // Step management
    const [email, setEmail] = useState("");
    const [emailExists, setEmailExists] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const toast = useToast();
    const login = JSON.parse(localStorage.getItem("login")) || "";
    const navigate=useNavigate()
    // Mock function to check email existence

    const checkEmailExists = async (email) => {
        // Simulate API call
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(email === "existing@example.com"); // Mock existing email
            }, 1000);
        });
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();


    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        
           try {
            const response = await fetch(`https://bigbullpaisewala.co.in/register/change-password/${login.permanent_ID}`,{
                method:"PUT",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({currentPassword,newPassword})
            })
            const res = await response.json();
            console.log(res);
            if(response.ok){
                toast({
                    title: "Password Changed successfully!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position:"top-center"
                });
                navigate("/")
            }else{
                toast({
                    title: `${res.message}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position:"top-center"
                });
            }
           } catch (error) {
            console.log(error)
            toast({
                title: `${error.message}`,
                status: "success",
                duration: 3000,
                isClosable: true,
                position:"top-center"
               
            });
           }
            
           
        
    };

    return (
        <Box
            minH="100vh"
            bgGradient="linear(to-r, red.50, red.100)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={10}
            p={{ base: 4, md: 8 }}
        >
            <Box
                maxW="lg"
                w="full"
                p={{ base: 4, md: 8 }} // Responsive padding for mobile and larger screens
                boxShadow="lg"
                borderRadius="lg"
                bg="white"
                border="1px solid"
                borderColor="red.200"
            >
                <Heading
                    as="h1"
                    size="lg"
                    mb={6}
                    textAlign="center"
                    color="red.600"
                    fontWeight="bold"
                >
                    {step === 2 ? "Set Password" : "Set Password"}
                </Heading>

                {step === 2 && (
                    <form onSubmit={handlePasswordSubmit}>
                        <VStack spacing={5}>
                            
                                <FormControl id="current-password" isRequired>
                                    <FormLabel color="red.500">Current Password</FormLabel>
                                    <Input
                                        type="password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        placeholder="Enter your current password"
                                        focusBorderColor="red.400"
                                    />
                                </FormControl>
                           
                            <FormControl id="new-password" isRequired>
                                <FormLabel color="red.500">New Password</FormLabel>
                                <Input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter your new password"
                                    focusBorderColor="red.400"
                                />
                            </FormControl>
                            {/* {!emailExists && (
                                <FormControl id="confirm-password" isRequired>
                                    <FormLabel color="red.500">Confirm Password</FormLabel>
                                    <Input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm your new password"
                                        focusBorderColor="red.400"
                                    />
                                </FormControl>
                            )} */}
                            <Button
                                type="submit"
                                colorScheme="red"
                                size="lg"
                                width="full"
                                _hover={{ bg: "red.500" }}
                                _active={{ bg: "red.600" }}
                            >
                                {emailExists ? "Reset Password" : "Set Password"}
                            </Button>
                        </VStack>
                    </form>
                )}
            </Box>
        </Box>
    );
};

export default SetResetPassword;