import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Heading,
    Image,
    Flex,
    Text,
    SimpleGrid,
    IconButton,
    Button,
    useBreakpointValue,
    Stack,
    Alert,
    AlertIcon,
    CloseButton,
    AlertDescription,
    AlertTitle,
    useDisclosure,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import BigBullWorldAds from './BigbullWorldAds';
import VendorCard from './Vendor';
import Header from './Header';
import BigbullWorldAdsTop from './BigbullWorldAdsTop';
import BigBullWorldCards from './BigbullWorldCards';


const CategoryProducts = () => {
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const [ratingsSummary, setRatingsSummary] = useState({});
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const login = JSON.parse(localStorage.getItem("login")) || "";
    const [storesingle, setStoreSingle] = useState({})
    const [filteredStores, setFilteredStores] = useState([]);
    const [query, setQuery] = useState('');
    const {
        isOpen: isVisible,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: true })
    const searchInputRef = useRef(null);

    useEffect(() => {
        // Automatically focus the input field when the component mounts
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    // Fetch categories
    const categoryFetch = async () => {
        try {
            const response = await fetch('https://bigbullpaisewala.co.in/category/getcategory');
            const res = await response.json();
            setCategories(res);
        } catch (error) {
            setError('Failed to fetch categories');
            console.error(error);
        }
    };


    const parseStoreData = (store) => {
        return {
            ...store,
            businessDetails: JSON.parse(store.businessDetails || "{}"),
            businessTiming: JSON.parse(store.businessTiming || "{}"),
            contactDetails: JSON.parse(store.contactDetails || "{}"),
            formImages: JSON.parse(store.formImages || "[]"),
            additionalServices: JSON.parse(store.additionalServices || "[]"),
            paymentDetails: JSON.parse(store.paymentDetails || "{}"),
            servicesDetails: JSON.parse(store.servicesDetails || "{}"),
        };
    };

    // Fetch stores
    const Allstorefetch = async () => {
        try {
            const response = await fetch('https://bigbullpaisewala.co.in/store/getstore/user');
            const res = await response.json();
            console.log(res);
            setStores(res.map(parseStoreData));
        } catch (error) {
            setError('Failed to fetch stores');
            console.error(error);
        }
    };

    // Fetch ratings summary for each store
    const fetchRatingsSummary = async (storeId) => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/rating/summary/${storeId}`);
            const res = await response.json();
            if (!res.isError) {
                setRatingsSummary((prevSummary) => ({
                    ...prevSummary,
                    [storeId]: {
                        count: res.count || 0,
                        average: res.average || 0,
                    },
                }));
            }
        } catch (error) {
            console.error('Failed to fetch ratings summary:', error);
        }
    };

    // Truncate store name if too long
    const truncateStoreName = (name) =>
        name.length > 20 ? name.slice(0, 20) + '...' : name;

    // Fetch categories and stores on component mount
    const singleStoreFetch = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/store/getstorelist/mystore/${login.permanent_ID}`)
            const res = await response.json();
            console.log(res);
            setStoreSingle(res);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        categoryFetch();
        Allstorefetch();
        singleStoreFetch();
    }, []);

    // Fetch ratings summary for all stores
    useEffect(() => {
        stores.forEach((store) => {
            fetchRatingsSummary(store.user_id);
        });
    }, [stores]);

    useEffect(() => {
        Allstorefetch().then(() => {
            setFilteredStores(stores);
        });
    }, [stores]);

    console.log(stores.status);
    // Store the breakpoint value at the top level of the component
    const chunkSize = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });

    // Handle Carousel Slide Movement
    const [currentSlide, setCurrentSlide] = useState({});
    const handleSlide = (category, direction, length) => {
        setCurrentSlide((prev) => ({
            ...prev,
            [category]: Math.min(
                Math.max((prev[category] || 0) + direction, 0),
                length - 1
            ),
        }));
    };

    

    const handleSearch = (e) => {
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);

        if (!searchQuery) {
            // Reset to show all stores if search query is empty
            setFilteredStores(stores);
            return;
        }

        // Filter and reorder stores
        const matchingStores = stores.filter((store) => {
            const businessName = store.businessDetails?.businessName?.toLowerCase() || '';
            const address = [
                store.businessDetails?.buildingName,
                store.businessDetails?.street,
                store.businessDetails?.city,
                store.businessDetails?.state,
            ]
                .filter(Boolean)
                .join(' ')
                .toLowerCase();
            const category = store.category?.toLowerCase() || '';

            return (
                businessName.includes(searchQuery) ||
                address.includes(searchQuery) ||
                category.includes(searchQuery)
            );
        });

        // Reorder: Place matching stores on top
        const nonMatchingStores = stores.filter(
            (store) => !matchingStores.includes(store)
        );

        setFilteredStores([...matchingStores, ...nonMatchingStores]);
    };

    

    return (
        <>
            <Header />
            <div className="sub-category-page-header">
                <div
                    className='back-btn-navigate'
                    onClick={() => navigate(-1)}
                    title='Go back'
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </div>
                <div className='sub-category-heading'>Big Bull World</div>
                <div></div>
            </div>
            <div className="sub-category-page-search">
                <input
                    // ref={searchInputRef}
                    type="search"
                    onClick={() => navigate("/search")}
                    placeholder='Type for search...'
                    value={query}
                    onChange={handleSearch}
                />
            </div>
            <Box >
                <BigbullWorldAdsTop />
                {
                    filteredStores.status === "Deactive" && isVisible ? <>
                        {/* <Stack spacing={3}> */}
                        <Alert status='success'>
                            <AlertIcon />
                            <Box>
                                {/* <AlertTitle>Success!</AlertTitle> */}
                                <AlertDescription>
                                    Your Store Status is under verification, it will get listed as soon as verification is done
                                </AlertDescription>
                            </Box>
                            <CloseButton
                                alignSelf='flex-end'
                                position='relative'
                                right={-1}
                                top={-1}
                                onClick={onClose}
                            />
                        </Alert>
                        {/* </Stack> */}
                    </> : null
                }
                <>
                    {categories.map((category) => {
                        const filteredStores1 = filteredStores.filter(
                            (store) =>
                                store.category === category.category_name && store.status === 'Active'
                        );

                        // console.log(filteredStores);
                        if (filteredStores1.length === 0) return null;

                        const chunkedStores = Array.from(
                            { length: Math.ceil(filteredStores1.length / chunkSize) },
                            (_, i) => filteredStores1.slice(i * chunkSize, i * chunkSize + chunkSize)
                        );

                        const currentIndex = currentSlide[category.category_name] || 0;

                        return (
                            <>
                                <Box p={5} key={category.category_name} mt={{base:"0px",sm:"0px",md:"0px",lg:"30px",xl:"30px"}} mb={{base:0,sm:0,md:0,lg:10,xl:10}} position="relative">
                                    {/* Category Header */}
                                    <Text fontSize={{ base: "", sm: "", md: "", lg: "18px", xl: "18px" }} color={"#5b5b5b"} fontWeight={"600"} mb={4} >
                                        {category.category_name}
                                    </Text>
                                    <div className="bigbullcard-list2">
                                        {filteredStores1.map((store, index) => (
                                            <BigBullWorldCards
                                                key={index}
                                                store={store}
                                                ratingsSummary={ratingsSummary}
                                            />
                                        ))}

                                    </div>
                                    {/* </SimpleGrid> */}
                                </Box>
                            </>
                        );
                    })}
                </>

                {/* <BigBullWorldCards/> */}
                <VendorCard />
                <BigBullWorldAds />
            </Box>
        </>
    );
};

export default CategoryProducts;