import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Global.css';
import ViewMore from '../Images/bigbull view more.png'

const CategoryGrid = () => {
  const [categories, setCategories] = useState([]);

  const fetchCategoryData = async () => {
    try {
      const response = await fetch('https://bigbullpaisewala.co.in/category/getcategory');
      const res = await response.json();
      console.log(res);
      const activeImages = res
        .filter((image) => image.status === 'Active')
        .sort((a, b) => a.position - b.position);
      setCategories(activeImages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  // Get categories to display (limit to first 20)
  const categoriesToDisplay = categories.slice(0, 19);

  return (
    <div className="category-grid">
      {categoriesToDisplay.map((category) => (
        <div key={category.id} className="category-item">
          <Link
            to={category.associatedsubcategory.length > 0 ? `/sub/${category.category_name}` : `/${category.category_name}`}
            className="category-link"
          >
            <div className="category-image">
              <img
                src={`https://bigbullpaisewala.co.in/${category.cat_image}`}
                alt={category.category_name}
              />
            </div>
            <p className="category-name">{category.category_name}</p>
          </Link>
        </div>
      ))}

      {/* Show "View More" button which redirects to the AllCategories component */}
      {categories.length > 19 && (
        <div className="view-more">
          <Link to="/all-categories">
            <div className="category-image">
              <img src={ViewMore} />
            </div>
            <p className='view-more-p'>View more</p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CategoryGrid;
