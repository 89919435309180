import '../CSS/Global.css'
import { Link } from 'react-router-dom'
import Header from './Header';

const CategorySingle = () => {
    return (
        <>
            <Header />
            <div className="category-back-name">
                <div className="back-to-home" title='Back to home'>
                    <i className="fa-solid fa-arrow-left"></i>
                    <Link to="/" className="back-to-home">Back</Link>
                </div>
                <div className="category-single-name">
                    Category name
                </div>
                <div></div>
            </div>
        </>
    );
};

export default CategorySingle;