import React, { useEffect, useRef, useState } from 'react';
import '../CSS/Global.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';

const Cosmetics = () => {
  const { category } = useParams();
  const [subcategory, setSubCategory] = useState([]);
  const [filteredSubCategory, setFilteredSubCategory] = useState([]);
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  const fetchCategory = async () => {
    try {
      const response = await fetch(`https://bigbullpaisewala.co.in/category/singlecategory/${category}`);
      const res = await response.json();
      setSubCategory(res.associatedsubcategory || []);
      setFilteredSubCategory(res.associatedsubcategory || []); // Initialize filtered data
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    // Automatically focus the input field when the component mounts
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = subcategory.filter((item) =>
      item.subcategory_name.toLowerCase().includes(query)
    );
    setFilteredSubCategory(filtered);
  };

  return (
    <>
      <Header />
      <div className="sub-category-page-header">
        <div
          className="back-btn-navigate"
          onClick={() => navigate(-1)}
          title="Go back"
        >
          <i className="fa-solid fa-arrow-left"></i>
        </div>
        <div className="sub-category-heading">{category}</div>
        <div></div>
      </div>
      <div className="sub-category-page-search">
        <input
          ref={searchInputRef}
          type="search"
          placeholder="Type for search..."
          onChange={handleSearch} // Add onChange handler for search
        />
      </div>
      <div className="category-grid">
        {filteredSubCategory.map((category) => (
          <div key={category.id} className="category-item">
            <Link
              to={`/subcategory/${category.subcategory_name}`}
              className="category-link"
            >
              <div className="category-image">
                <img
                  src={`https://bigbullpaisewala.co.in/${category.subcat_image}`}
                  alt={category.subcategory_name}
                />
              </div>
              <p className="category-name">{category.subcategory_name}</p>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default Cosmetics;
