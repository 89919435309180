import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';
import Header from './Header';
import track from '../Images/track.webp';
import vegetable from '../Images/004-vegetable.png'
import fruits from '../Images/009-fruits.png'
import { useNavigate, useParams } from 'react-router-dom';
import { Button, useDisclosure } from '@chakra-ui/react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react'

const Track = () => {
    const [activeStep, setActiveStep] = useState(1); // Default active step: "dispatch"

    const steps = ["order placed", "dispatch", "out for delivery", "delivered"];

    let navigate = useNavigate();
    let [order, setOrder] = useState({});
    let [orderprod, setOrderProd] = useState([])
    let login = JSON.parse(localStorage.getItem("login")) || "";
    let { id } = useParams()
    let [totalPrice, setTotalPrice] = useState(0);
    let [totalStrikeOffPrice, setTotalStrikeOffPrice] = useState(0);
    let [addresspro, setAddressPro] = useState({})
    const { isOpen, onOpen, onClose } = useDisclosure()

    const statusToStep = {
        "Order Placed": 1,
        "Dispatch": 2,
        "Out for Delivery": 3,
        "Delivered": 4,
    };

    const fetchOrderData = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/cart-orders/order/${id}`);
            const res = await response.json();
            console.log(res.data);

            // Parse cart_product and user_address fields
            const parsedOrder = {
                ...res.data,
                cart_product: JSON.parse(res.data.cart_product || "[]"), // Parse cart_product JSON
                user_address: JSON.parse(res.data.user_address || "{}"), // Parse user_address JSON
                vendor_address: JSON.parse(res.data.vendor_address || "{}"), // Parse vendor_address JSON
            };

            // Set parsed order details
            setOrder(parsedOrder);
            setOrderProd(parsedOrder.cart_product);
            setAddressPro(parsedOrder.user_address);

            // Calculate total prices
            let totalPrice = 0;
            let totalStrikeOffPrice = 0;

            parsedOrder.cart_product.forEach((product) => {
                totalPrice += product.product.mrp_price * product.quantity;
                totalStrikeOffPrice += product.product.sell_price * product.quantity;
            });

            const step = statusToStep[parsedOrder.status] || 1; // Default to "Order Placed" if status is unknown
            setActiveStep(step);
            setTotalPrice(totalPrice);
            setTotalStrikeOffPrice(totalStrikeOffPrice);
        } catch (error) {
            console.log(error);
        }
    };



    console.log(totalPrice)
    console.log(totalStrikeOffPrice)

    let logout = () => {

        localStorage.removeItem("login");
        window.location.reload();
    }

    useEffect(() => {
        fetchOrderData();
    }, [])

    return (
        <>
            <AlertDialog
                motionPreset='slideInBottom'
                // leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Invoice</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Download Invoice  Coming Soon!
                        {/* Are you sure you want to discard all of your notes? 44 words will be
                        deleted. */}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        {/* <Button ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3}>
                            Yes
                        </Button> */}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <Header />
            <div className="track">
                <img src={track} alt="Track your order" />
            </div>
            <div className="track-mapping">
                <div className="track-order-number">
                    <p className="order-id">Order #{order.transaction_id}</p>
                </div>
                <div className="mapping">
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`step 
                                    ${index + 1 < activeStep ? "active" : ""}
                                    ${index + 1 === activeStep ? "active pulse" : ""}
                                `}
                                // onClick={() => setActiveStep(index + 1)} // Optional: Update step on click
                            >
                                <span className="dot"></span>
                                <p>{step}</p>
                            </div>
                            {index < steps.length - 1 && <div className="line"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="track-orders-list">
                <div className="ordered-items">Ordered Items</div>
                {orderprod.map((product, index) => (
                    <div className="item-list" key={index}>
                        <div className="ordered-item-img">
                            <img
                                src={`https://bigbullpaisewala.co.in/${product.product.product_image}`}
                                alt={product.product.product_name}
                            />
                        </div>
                        <div className="ordered-item-detail">
                            <p className="product-name">{product.product.product_name}</p>
                            <p className="actual-amount">
                                <span className="line-through">₹{product.product.mrp_price.toFixed(2)}</span>
                                <span className="percent-discount">
                                    {Math.round(
                                        ((product.product.mrp_price - product.product.sell_price) /
                                            product.product.mrp_price) *
                                        100
                                    )}
                                    % Off
                                </span>
                            </p>
                            <div className="discount-quantity">
                                <p className="discount-amount">₹{product.product.sell_price.toFixed(2)}</p>
                                <p className="quantity">{product.quantity} Qty</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="bill-details">
                <div className="bill-details-header">Bill Details</div>
                <div className="bill-saved">
                    You saved ₹{(totalPrice - totalStrikeOffPrice).toFixed(2)} on this order
                </div>
                <div className="mrp-div">
                    <p className="mrp-title">MRP</p>
                    <p className="mrp">₹{totalPrice.toFixed(2)}</p>
                </div>
                <div className="discount-div">
                    <p className="discount-title">Discount</p>
                    <p className="discount">-₹{(totalPrice - totalStrikeOffPrice).toFixed(2)}</p>
                </div>
                {/* <div className="tax-div">
                    <p className="tax-title">Tax</p>
                    <p className="taxes">₹11.00</p>
                </div> */}
                <div className="delivery-div">
                    <p className="delivery-title">Delivery Charges</p>
                    <p className="delivery-charge">₹{order.delivery_amount || 0.0}</p>
                </div>
                <div className="bill-total">
                    <p className="payable">Payable</p>
                    <p className="total-amt">
                        ₹{(totalStrikeOffPrice + (order.delivery_amount || 0.0)).toFixed(2)}
                    </p>
                </div>
                <div className="payment-mode">
                    <p className="payment-mode-title">Payment Mode</p>
                    <p className="type">{order.payment_mode}</p>
                </div>
                <div className="download-invoice">
                    {/* <button className="download" onClick={()=>navigate(`/invoice`)}>
                        Download Invoice
                    </button> */}
                </div>
            </div>
        </>
    );

};

export default Track;
