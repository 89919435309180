import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../CSS/Global.css";
import Header from "../Components/Header";
import CryptoJS from "crypto-js"; // For decryption
import orderimg from "../Images/noorder.jpg";

const NewOrder = () => {
  const [filter, setFilter] = useState("all-order");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [order, setOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const login = JSON.parse(localStorage.getItem("login")) || "";

  const decrypt = (encryptedData, iv) => {
    const key = "ThisIsA32CharacterLongKey123456!";

    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const ivHex = CryptoJS.enc.Hex.parse(iv);

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Hex.parse(encryptedData) },
      keyHex,
      {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  const fetchOrderData = async () => {
    try {
      const response = await fetch(
        `https://bigbullpaisewala.co.in/cart-orders/users/${login.permanent_ID}`
      );
      const res = await response.json();
      const decryptedData = decrypt(res.data.data, res.data.iv);

      if (decryptedData) {
        const parsedData = JSON.parse(decryptedData).map((order) => ({
          ...order,
          cart_product: JSON.parse(order.cart_product),
          user_address: JSON.parse(order.user_address),
          vendor_address: JSON.parse(order.vendor_address),
        }));
        setOrder(parsedData.reverse());
      } else {
        console.error("Decrypted data is empty or invalid");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredOrders =
    filter === "all-order"
      ? order
      : order.filter((o) => o.status.toLowerCase() === filter.toLowerCase());

  useEffect(() => {
    fetchOrderData();
  }, []);

  return (
    <>
      <Header />
      <div className="new-orders">
        <div className="new-order-container">
          <div className="order-page-heading">
            <h2>My orders history</h2>
          </div>
          <div className="order-dropdown">
            <select
              name="order-select"
              id="order-select"
              value={filter}
              onChange={handleFilterChange}
            >
              <option value="all-order">All Orders</option>
              <option value="Pending">Pending</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Delivered">Delivered</option>
            </select>
          </div>
          {filteredOrders.length > 0 ? (
            filteredOrders.map((order) => (
              <div className="new-order-card" key={order.transaction_id}>
                <div className="order-number">
                  <p className="order-id">Order #{order.transaction_id}</p>
                  <p className="order-date">
                    On {new Date(order.createdAt).toLocaleDateString()}
                  </p>
                </div>
                <div className="order-status">
                  <p className={order.status.toLowerCase()}>
                    Order {order.status.charAt(0).toUpperCase() +
                      order.status.slice(1)}
                  </p>
                </div>
                <div className="order-list">
                  <h4>Items:</h4>
                  {order.cart_product.map((item, i) => (
                    <div key={i} className="item-details">
                      <p>
                        <strong>Product:</strong> {item.product.product_name}
                      </p>
                      <p>
                        <strong>Quantity:</strong> {item.quantity}
                      </p>
                      <p>
                        <strong>Price:</strong> ₹
                        {item.product.sell_price.toFixed(2)}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="amount-tract-btn">
                  <div className="order-amount">
                    <p>Total Amount</p>
                    <h3>₹{(order.amount_p || 0).toFixed(2)}</h3>
                  </div>
                  <div className="order-track-btn">
                    <button
                      className="track-btn"
                      onClick={() =>
                        navigate(`/track/${order.transaction_id}`)
                      }
                    >
                      Track Order
                    </button>
                  </div>
                </div>
                <div className="ordered-by">
                  <p>
                    Ordered by:{" "}
                    <span className="ordered-by-name">
                      {order.user_address.name}
                    </span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="order-imagebestview">
              <img className="order-imageview" src={orderimg} alt="No orders" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewOrder;
