import { useState, useEffect } from 'react';
import '../CSS/Global.css';
import FooterLink from './FooterLink';
import Header from './Header';
import userimg from "../Images/user.jpg"
import redbg from "../Images/redcolor.jpg"

const VendorProfile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const login = JSON.parse(localStorage.getItem("login")) || ""
    const [profileDetails, setProfileDetails] = useState({
        id: '',
        name: '',
        mobile: '',
        email: '',
        permanent_ID: '',
        profilePic: '',
        account_number: '',
        beneficary_name: '',
        ifsc_code: '',
        bank_name: '',

    });
    const [isBankDetailsUpdated, setIsBankDetailsUpdated] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [storelist, setStoreList] = useState({})

    useEffect(() => {
        const bankUpdated = localStorage.getItem('bankDetailsUpdated');
        if (bankUpdated === 'true') {
            setIsBankDetailsUpdated(true);
        }
    }, []);

    const handleEdit = async () => {
        const formData = new FormData();
        formData.append('name', profileDetails.name);
        formData.append('mobile', profileDetails.mobile);
        formData.append('email', profileDetails.email);
        formData.append('account_number', profileDetails.account_number);
        formData.append('beneficary_name', profileDetails.beneficary_name);
        formData.append('ifsc_code', profileDetails.ifsc_code);
        formData.append('bank_name', profileDetails.bank_name);
        if (selectedFile) {
            formData.append('profilePic', selectedFile); // Attach the selected file
        }

        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/register/change/${profileDetails.id}`, {
                method: 'PUT',
                body: formData, // Use FormData
            });
            if (response.ok) {
                fetchDataUser(); // Refresh the profile details
                setIsEditing(false); // Exit edit mode
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDataUser = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/register/singleuser/${login.permanent_ID}`)
            const res = await response.json();
            setProfileDetails(res)
            if (res.bank_name !== null && res.beneficary_name !== null && res.account_number !== null && res.ifsc !== null) {
                setIsBankDetailsUpdated(true)
            }
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }
    const handleChange = (e) => {
        setProfileDetails({
            ...profileDetails,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]); // Set the selected file
    };

    const storeListing = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/store/getstorelist/mystore/${login.permanent_ID}`);
            const res = await response.json();

            // Parsing the fetched data
            const parsedData = {
                ...res,
                additionalEmails: JSON.parse(res.additionalEmails || "[]"),
                additionalLandlines: JSON.parse(res.additionalLandlines || "[]"),
                additionalMobiles: JSON.parse(res.additionalMobiles || "[]"),
                additionalServices: JSON.parse(res.additionalServices || "[]"),
                additionalWhatsapps: JSON.parse(res.additionalWhatsapps || "[]"),
                businessDetails: JSON.parse(res.businessDetails || "{}"),
                businessTiming: JSON.parse(res.businessTiming || "{}"),
                contactDetails: JSON.parse(res.contactDetails || "{}"),
                formImages: JSON.parse(res.formImages || "[]"),
                paymentDetails: JSON.parse(res.paymentDetails || "{}"),
                servicesDetails: JSON.parse(res.servicesDetails || "{}"),
                planObject: JSON.parse(res.planObject || "{}"),
            };

            console.log(parsedData);
            setStoreList(parsedData)
            // Setting parsed data in state


            if (response.ok) {

            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchDataUser();
        storeListing();
    }, [])

    return (
        <>
            <Header />
            <div className="user-profile">
                <div className="my-profile">
                    <h2>My Profile</h2>
                    {
                        isEditing ? <button onClick={handleEdit} className={`edit-btn save`}>
                            {'Save'}
                        </button>
                            : <button onClick={() => setIsEditing(true)} className={`edit-btn edit`}>
                                {'Edit'}
                            </button>
                    }
                </div>
                <div className="user-profile-column">
                    <div className="user-profile-left">
                        <h2>Personal Information</h2>
                        <div className="user-profile-img">
                            <img src={redbg} alt="user-profile" className='user-profile-img1' />
                            <img
                                src={
                                    profileDetails.profilePic
                                        ? `https://bigbullpaisewala.co.in/${profileDetails.profilePic}`
                                        : userimg
                                }
                                alt="User"
                                className="user-profile-img2"
                            />

                        </div>
                        <div className="vendor-profile-business-name">

                            <div className="vendor-profile-business-name">
                                {storelist && storelist.businessDetails && storelist.businessDetails.businessName ? (
                                    <p className="business-name">{storelist.businessDetails.businessName}</p>
                                ) : (
                                    <p className="business-name">N/A</p>
                                )}
                            </div>


                        </div>
                        <div className="user-profile-name vendor-profile-name">
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="name"
                                    value={profileDetails.name}
                                    onChange={handleChange}
                                    placeholder="Enter your name"
                                    className="input-field"
                                />
                            ) : (
                                <p className="user-name">{profileDetails.name}</p>
                            )}
                        </div>
                        <div className="user-profile-contact">
                            <p>
                                Contact: {isEditing ? (
                                    <input
                                        type="text"
                                        name="mobile"
                                        value={profileDetails.mobile}
                                        onChange={handleChange}
                                        placeholder="Enter contact number"
                                        className="input-field"
                                    />
                                ) : (
                                    <span>{profileDetails.mobile}</span>
                                )}
                            </p>
                            <p>
                                Email: {isEditing ? (
                                    <input
                                        type="email"
                                        name="email"
                                        value={profileDetails.email}
                                        onChange={handleChange}
                                        placeholder="Enter email"
                                        className="input-field"
                                    />
                                ) : (
                                    <span>{profileDetails.email}</span>
                                )}
                            </p>
                            <p>
                                Permanent ID: {isEditing ? (
                                    <input
                                        type="text"
                                        name="permanentID"
                                        value={profileDetails.permanent_ID}
                                        onChange={handleChange}
                                        placeholder="Enter permanent ID"
                                        className="input-field"
                                        disabled
                                    />
                                ) : (
                                    <span>{profileDetails.permanent_ID}</span>
                                )}
                            </p>
                            {/* <p>
                                Address: {isEditing ? (
                                    <input
                                        type="text"
                                        name="address"
                                        value={profileDetails.address}
                                        onChange={handleChange}
                                        placeholder="Enter address"
                                        className="input-field"
                                    />
                                ) : (
                                    <span>{profileDetails.address}</span>
                                )}
                            </p> */}
                        </div>
                    </div>
                    <div className="user-profile-right">
                        {storelist && storelist.formImages ? (
                            <div className="vendor-listing">
                                <h2>Your Listings</h2>
                                <div className='listing-card'>
                                    <div className='vendor-listing-img'>
                                        <img src={`https://bigbullpaisewala.co.in/${storelist?.formImages[0]}`} alt='Vendor' />
                                    </div>
                                    <div className='listing-name'>
                                        {storelist.businessDetails.businessName}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className="business-name">N/A</p>
                        )}



                        {/* <div className="user-profile-password">
                            <h2>Privacy Details</h2>
                            <div className="password">
                                <input
                                    type="text"
                                    name="password"
                                    value={profileDetails.password}
                                    onChange={handleChange}
                                    placeholder="Enter your password"
                                    className="input-field"
                                    disabled={!isEditing}
                                />
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    value={profileDetails.confirmPassword}
                                    onChange={handleChange}
                                    placeholder="Confirm your password"
                                    className="input-field"
                                    disabled={!isEditing}
                                />
                            </div>
                        </div> */}
                        <div className="user-profile-bank">
                            <h2>
                                Bank Details
                                {/* Show the span only if the bank details have not been updated */}
                                {!isBankDetailsUpdated && (
                                    <span>
                                        <i className="fa-solid fa-exclamation"></i>
                                        Update Your Bank Details
                                    </span>
                                )}
                            </h2>
                            <div className="bank-details">
                                <input
                                    type="text"
                                    name="accountNumber"
                                    value={profileDetails.account_number}
                                    onChange={handleChange}
                                    placeholder="Account Number"
                                    className="bank-input"
                                    disabled={!isEditing}
                                />
                                <input
                                    type="text"
                                    name="beneficary_name"
                                    value={profileDetails.beneficary_name}
                                    onChange={handleChange}
                                    placeholder="Eneter your name"
                                    className="bank-input"
                                    disabled={!isEditing}
                                />
                                <input
                                    type="text"
                                    name="ifsc"
                                    value={profileDetails.ifsc_code}
                                    onChange={handleChange}
                                    placeholder="IFSC Code"
                                    className="bank-input"
                                    disabled={!isEditing}
                                />
                                <input
                                    type="text"
                                    name="bankName"
                                    value={profileDetails.bank_name}
                                    onChange={handleChange}
                                    placeholder="Bank Name"
                                    className="bank-input"
                                    disabled={!isEditing}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterLink />
        </>
    );
};

export default VendorProfile;
