import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const PhotoUploadModal = ({ isOpen, onClose, setIsModalOpen, handleFileUpload, uploadedFiles, storeId, detailVendorGet,setUploadedFiles }) => {
  if (!isOpen) return null;
  const handleSubmitAllImages = async () => {
    const formData = new FormData();
    uploadedFiles.forEach((file) => formData.append("formImages", file));
    try {

      const response = await axios.put(
        `https://bigbullpaisewala.co.in/store/add-store-images/${storeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setUploadedFiles([]);
        // alert("Images added successfully!");
        setIsModalOpen(false);
        detailVendorGet();
     
    } catch (error) {
      console.error("Error uploading images:", error);
      // alert("Failed to upload images. Please try again.");
    }
  }


  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Upload Photos</h2>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileUpload}
        />
        {uploadedFiles.length > 0 && (
          <div className="uploaded-files">
            <h3>Uploaded Files:</h3>
            <ul>
              {uploadedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
        <div>
          <button onClick={handleSubmitAllImages}>Submit</button>
          <button style={{ marginLeft: "10px" }} onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};


const DetailImageMobile = ({detailVendorGet, storeSingle, formImages }) => {
  const detailMobile = {
    dots: false,             // Show dots for navigation
    infinite: false,          // Infinite loop scrolling
    speed: 500,              // Transition speed
    slidesToShow: 1,         // Show 1 slide at a time
    slidesToScroll: 1,       // Scroll 1 slide at a time
    arrows: false,           // No arrows for a cleaner mobile UI
    swipeToSlide: true,      // Enable swiping functionality
    autoplay: false,          // Enable auto-sliding
    autoplaySpeed: 3000,     // Slide transition every 3000ms (3 seconds)
  };
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

 

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  
  return (
    <div className="mobile-carousel">
      <Slider {...detailMobile}>
        {formImages.map((image, index) => (
          <div key={index} onClick={() => navigate(`/gallery/${storeSingle.id}/${storeSingle.businessDetails.businessName}`)}>
            <img
              src={`https://bigbullpaisewala.co.in/${image}`}
              alt={`Image ${index + 1}`}
            />
          </div>
        ))}
        {/* Option to add more images */}
        <div >
          <img
            // onClick={() => navigate(`/gallery/${storeSingle.id}/${storeSingle.businessDetails.businessName}`)}
            onClick={() => setIsModalOpen(true)}
            src={formImages && formImages?.length > 5
              ? `https://bigbullpaisewala.co.in/${formImages[5]}`
              : (formImages?.length > 0
                ? `https://bigbullpaisewala.co.in/${formImages[0]}`
                : "https://picsum.photos/400/300?random=6"
              )
            }
            alt="Add more images to gallery"
            style={{ width: "100%", height: "auto", opacity: 0.2 }}
          />
          <div className="overlay1" onClick={() => setIsModalOpen(true)}>
            <label htmlFor="upload-input" style={{ cursor: "pointer" }}>
              Add More Photo
            </label>
            {/* <input
              id="upload-input"
              type="file"
              accept="image/*"
              multiple
              style={{ display: "none" }}
            /> */}
          </div>
        </div>
      </Slider>
      <div className='detail-popup'>
        <PhotoUploadModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          setIsModalOpen={setIsModalOpen}
          handleFileUpload={handleFileUpload}
          uploadedFiles={uploadedFiles}
          storeId={storeSingle.id}
          detailVendorGet={detailVendorGet}
          setUploadedFiles={setUploadedFiles }
        />
      </div>
    </div>
  );
};

export default DetailImageMobile;
