import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../CSS/Global.css';
import logo from '../Images/160X60-BIGBULL-PAISEWALA.png';
import emptycart from "../Images/emptycart.webp"
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    Input,
    Box,
    Image,
    Flex,
    Text,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Divider,
    Switch,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useGlobalContext } from './Context/GlobalContext';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import userimg from "../Images/user.jpg"

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAmount1, setTotalAmount1] = useState(0);
    const drawerSize = useBreakpointValue({ base: "full", md: "xs" });
    let [allAdd, setAdd] = useState([]);
    const [deliveryPrice, setTotalDeliveryPrice] = useState(0);
    const {
        isOpen,
        onOpen,
        onClose,
        isModal1Open,
        openModal1,
        closeModal1,
        fetchCartData,
        cart,
        totalCartItem,
        totaldelivery,
        setTotalDelivery,
        totalhandle
    } = useGlobalContext();
    const user = {
        name: 'Aditya More',
        avatar: 'https://via.placeholder.com/40',
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(5);
    const [isTabOpen, setIsTabOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const [isPlus, setIsPlus] = useState(true); // New state to track icon state
    let [addressget, setAddressGet] = useState([])
    const [storeMain, setStoreMain] = useState({})
    const login = JSON.parse(localStorage.getItem("login")) || ""
    let selectedAddress = JSON.parse(localStorage.getItem("clickedAddress")) || ""
    const [isToggleOn, setIsToggleOn] = useState(false);
    const toast = useToast();
    const { isOpen: isModal2Open, onOpen: openModal2, onClose: closeModal2 } = useDisclosure();
    const { isOpen: isModal3Open, onOpen: openModal3, onClose: closeModal3 } = useDisclosure();

    const handleToggle = () => {
        setIsToggleOn(!isToggleOn);
    };


    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const handleDeleteAccount = () => {
        onOpenDelete();
    };

    const confirmDelete = () => {
        toast({
            title:`Your request is under review`,
            description: `It will get updated within 24hours`,
            status: 'success',
            duration: 5000,
            position: 'top-center',
            isClosable: true,
        });
        console.log('Account deleted'); // Replace this with actual delete logic
        onCloseDelete();

    };

    const btnRef = useRef()
    const toggleTab = () => {
        setIsTabOpen(!isTabOpen);
        setIsPlus(!isPlus); // Toggle plus/cross state
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab === activeTab ? null : tab);
    };

    const handleSearchClick = () => {
        navigate('/search');
    };

    // const handleMessagesClick = () => {
    //     setIsChatOpen(!isChatOpen);
    // };



    const calculateTotalAmount = () => {
        const total = cart.reduce((acc, item) => {
            return acc + item.product.sell_price * item.quantity;
        }, 0);
        setTotalAmount(total);
    };

    const calculateTotalAmount1 = () => {
        const total = cart.reduce((acc, item) => {
            return acc + item.product.mrp_price * item.quantity;
        }, 0);
        setTotalAmount1(total);
    };


    const [store, setStore] = useState(false);
    const GetSingleStoreInCart = async () => {
        console.log(cart)
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/store/getstorelist/mystore/${login.permanent_ID}`);
            const store = await response.json();
            console.log(store);
            if (response.ok) {
                setStore(true);
            } else if (response.status === 400) {
                setStore(false);
            }
            const parsedData = {
                ...store,
                additionalEmails: JSON.parse(store.additionalEmails || "[]"),
                additionalLandlines: JSON.parse(store.additionalLandlines || "[]"),
                additionalMobiles: JSON.parse(store.additionalMobiles || "[]"),
                additionalServices: JSON.parse(store.additionalServices || "[]"),
                additionalWhatsapps: JSON.parse(store.additionalWhatsapps || "[]"),
                businessDetails: JSON.parse(store.businessDetails || "{}"),
                businessTiming: JSON.parse(store.businessTiming || "{}"),
                contactDetails: JSON.parse(store.contactDetails || "{}"),
                formImages: JSON.parse(store.formImages || "[]"),
                paymentDetails: JSON.parse(store.paymentDetails || "{}"),
                servicesDetails: JSON.parse(store.servicesDetails || "{}"),
            };
            setStoreMain(parsedData);
            const { buildingName, street, area, city, state, pincode } = store.businessDetails;
            const storeAddress = `${buildingName}, ${street}, ${area}, ${city}, ${state} - ${pincode}`;

            const selectedAddress = JSON.parse(localStorage.getItem("clickedAddress"));
            const userAddress = selectedAddress.area;

            const storeadd = "Chandresh corner B-1/B-2, sai nagar, Station Rd, Sriprastha, Nalasopara West, Nala Sopara, Maharashtra 401203, India";

            // Calculate distance
            const distanceResponse = await axios.get(`https://bigbullpaisewala.co.in/address/distance`, {
                params: {
                    origins: encodeURIComponent(storeadd),
                    destinations: encodeURIComponent(userAddress)
                }
            });

            const distanceData = distanceResponse.data;
            const distanceInMeters = distanceData.rows[0].elements[0].distance.value; // in meters
            const distanceInKilometers = distanceInMeters / 1000;

            console.log(`Distance: ${distanceInKilometers} km`);

            // Calculate delivery charge based on the distance
            const deliveryCharge = calculateDeliveryCharge(distanceInKilometers);
            console.log(`Delivery Charge: ${deliveryCharge}`);

            // Set the delivery charge state
            setTotalDeliveryPrice(deliveryCharge);
        } catch (error) {
            console.log(error);
        }
    };

console.log(storeMain)
    const calculateDeliveryCharge = (distance) => {
        // Iterate over the totaldelivery array to find the matching range
        for (let i = 0; i < totaldelivery.length; i++) {
            const { min_kilometer, max_kilometer, delivery_price } = totaldelivery[i];

            // Check if the distance falls within the current range
            if (distance >= min_kilometer && distance < max_kilometer) {
                return delivery_price; // Return the corresponding delivery price
            }
        }

        // If the distance doesn't match any range, return a default value (optional)
        return 0; // or handle accordingly (e.g., max range, custom charge)
    };

    // Recalculate the total amount whenever the cart changes
    useEffect(() => {
        calculateTotalAmount();
        calculateTotalAmount1();
    }, [cart]);



    // console.log(totalAmount)
    const fetchDataAddress = async () => {
        try {
            let response = await fetch(`https://bigbullpaisewala.co.in/address/${login.permanent_ID}`)
            let res = await response.json();
            setAdd(res.user)
        } catch (error) {
            console.log(error)
        }
    }

    const handleIncrement = async (id, currentQuantity) => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/cart/quantity/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ quantity: currentQuantity + 1 })
            });

            const data = await response.json();
            if (response.ok) {
                fetchCartData();
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDecrement = async (id, currentQuantity) => {
        try {
            if (currentQuantity > 1) {
                const response = await fetch(`https://bigbullpaisewala.co.in/cart/quantity/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ quantity: currentQuantity - 1 })
                });

                const data = await response.json();
                if (response.ok) {
                    fetchCartData();
                } else {
                    console.error(data.message);
                }
            } else {
                console.log("Quantity cannot be less than 1");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const updateDefaultAddress = async (id) => {
        try {
            // Set all addresses' defaultaddress to false
            setAdd(prevState =>
                prevState.map(address => {
                    return { ...address, defaultaddress: false };
                })
            );

            // Update the clicked address to default
            const response = await fetch(`https://bigbullpaisewala.co.in/address/${id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ defaultaddress: true }),
            });

            if (response.ok) {
                // Update the default address in the state
                setAdd(prevState =>
                    prevState.map(address => {
                        if (address.id === id) {
                            return { ...address, defaultaddress: true };
                        } else {
                            return address;
                        }
                    })
                );
                console.log(allAdd)
                console.log("Default address updated successfully.");
            } else {
                console.log("Failed to update default address.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickAddress = async (id) => {
        try {
            // Update the default address
            await updateDefaultAddress(id);
            GetSingleStoreInCart();
            // Find the clicked address from allAdd array
            const clickedAddress = allAdd.find(address => address.id === id);

            // Serialize the clicked address object before storing in local storage
            const serializedAddress = JSON.stringify(clickedAddress);

            // Store the serialized address in local storage
            localStorage.setItem('clickedAddress', serializedAddress);

            // Close the modal
            closeModal1();
            // onOpen();

            // Perform any other action needed when clicking on an address
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/address/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },

            })
            const res = await response.json();
            if (response.ok) {
                fetchDataAddress();
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
    }

    let [latitude, setLatitude] = useState();
    let [longitude, setLongitude] = useState();

    let handleDetect = () => {
        if (!navigator.geolocation) {
            // Geolocation is not supported by the browser
            console.log("Geolocation is not supported by your browser.");
            return;
        }

        // Try to get the current position
        // setLoad(false);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLongitude(position.coords.longitude);
                setLatitude(position.coords.latitude);
                localStorage.setItem("lat", position.coords.latitude)
                localStorage.setItem("lon", position.coords.longitude)
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`;
                fetch(url)
                    .then((res) => res.json())
                    .then((res) => {
                        // calculateRoute(res.results[0].formatted_address);
                        // // setAddress(res.results[0].formatted_address);
                        localStorage.setItem("address", JSON.stringify(res.results[0].formatted_address))
                        // setLoad(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        // setLoad(false);
                    });
            },
            () => {
                // If the user denied geolocation access
                console.log("Please allow geolocation access to detect your location.");
                // setLocationWarning(true);
                // openModal4()
                // setLoad(true); // Reset the loading state
            }
        );
    };

    const handleNavigationLoce = () => {
        // console.log("hi")
        closeModal1();
        // onClose();
        navigate("/address");

        handleDetect();
    }

    const handlechecknext = () => {
        if (selectedAddress) {
            handleOrderDone();

        } else {
            handlemodalopen();
        }
    }

    const handleOrderDone = async () => {
        let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
        const totalCartAmount = parseFloat(totalAmount + deliveryPrice + totalhandle)
        let paymentDetails = {
            user_id: login.permanent_ID,
            user_phone: login.name,
            transaction_id: orderId,
            amount: totalCartAmount * 100,
            currency: cart[0].store_id,
            payment_capture: 1,
            payment_mode: "Cash",
            cart_product: cart,
            amount_p: totalAmount,
            delivery_amount: deliveryPrice,
            handling_amount: totalhandle,
            user_address: selectedAddress,
            vendor_address: selectedAddress
        };
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/cart-orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(paymentDetails),
            });

            const res = await response.json();

            if (response.ok) {

                const deleteResponse = await fetch(`https://bigbullpaisewala.co.in/cart/deleteall/${login.permanent_ID}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (deleteResponse.ok) {
                    // Optionally, you can fetch updated cart or set it to an empty array in state
                    // setCart([]);
                    navigate(`/order/thankyou/${paymentDetails.transaction_id}`)
                    onClose();
                    fetchCartData();
                } else {
                    console.log("Failed to delete cart items");
                }
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handlemodalopen = () => {
        onClose();
        openModal1();
    }

    const handleRemoveItem = async (id) => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/cart/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const res = await response.json();
            if (response.ok) {
                fetchCartData();
            }
        } catch (error) {
            console.log(error)
        }
    }

    let fetchAddressdata = async () => {
        try {
            let response = await fetch(`https://bigbullpaisewala.co.in/address/${login.permanent_ID}`)
            let res = await response.json();
            setAddressGet(res.user)
        } catch (error) {
            console.log(error);
        }
    }

    const [userData, setUserData] = useState(null);
    const [isPaid, setIsPaid] = useState(false);

    // Decrypt function (mirrors backend decryption)
    const decrypt = (encryptedData, iv) => {
        const key = 'ThisIsA32CharacterLongKey123456!'; // 32-character key used on the backend

        // Convert key, IV, and encrypted data to appropriate CryptoJS formats
        const keyHex = CryptoJS.enc.Utf8.parse(key);  // Convert the key to a CryptoJS format
        const ivHex = CryptoJS.enc.Hex.parse(iv);     // Convert IV from 'hex'

        // Decrypt the data using AES with CBC mode
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(encryptedData) }, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Return the decrypted UTF-8 string
        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`https://bigbullpaisewala.co.in/payments/users/${login.permanent_ID}`);
            const encryptedData = response.data.data;
            // Decrypt the data
            const decryptedData = decrypt(encryptedData.encryptedData, encryptedData.iv);
            // Ensure that the decrypted data is valid before parsing
            if (decryptedData) {
                const parsedData = JSON.parse(decryptedData);
                setUserData(parsedData);
                // Check if any object's status is 'Paid'
                const hasPaidStatus = parsedData.some(user => user.status === 'Paid');
                setIsPaid(hasPaidStatus); // Set the isPaid state
                // Open modal if not paid
                if (!hasPaidStatus && login !== "") {
                    // onOpen(); // Open the modal if no status is 'Paid'
                }
            } else {
                console.error("Decrypted data is empty or invalid");
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
    const [profileDetails, setProfileDetails] = useState({


    });

    const fetchDataUser = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/register/singleuser/${login.permanent_ID}`)
            const res = await response.json();
            setProfileDetails(res)
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        fetchDataAddress();
        fetchAddressdata();
        fetchCartData();
        GetSingleStoreInCart();
        fetchUserData();
        fetchDataUser();
    }, [])

    const handlepayfirst = () => {
        if (login) {
            toast({
                description: "Pay Registeration Fee First",
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
        } else {
            navigate("/login")
            toast({
                description: "Login First",
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
        }

    }

    const handlelogout = () => {
        console.log("hiii")
        localStorage.clear();
        navigate("/login")
    }

    const handledelete = () => {
        // console.log("hiii")
        // localStorage.clear();
        // navigate("/login")
    }

    const handleNavigate = (ele) => {
        onClose();
        closeModal1();
        navigate(`/single/${ele}`)

    }

    const handleCheckProduct = () => {
        if (store) {
            navigate("/product")
        } else {
            toast({
                description: "Register your store first",
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
        }
    }

    return (
        <>
            <Modal isOpen={isOpenDelete} onClose={onCloseDelete}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Deletion</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete your account? You can not recover your account.
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={confirmDelete} mr={3}>
                            Yes, Delete
                        </Button>
                        <Button variant="ghost" onClick={onCloseDelete}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Drawer
                isOpen={isModal1Open}
                placement='right'
                onClose={closeModal1}
                size={drawerSize}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent >
                    <DrawerCloseButton />
                    <DrawerHeader boxShadow={"none"} color={"black"} borderBottom={"1px solid #eee"}>Select Address</DrawerHeader>

                    <DrawerBody mt={"10px"}>
                        <Box borderRadius={"10px"} bg={"white"} p={"0px 12px 12px 2px"} cursor={"pointer"} onClick={handleNavigationLoce}>
                            <Flex alignItems={"center"} color={"#1e90ff"} gap={"10px"} fontWeight={"bold"}>
                                <i class="fa-solid fa-plus"></i>
                                <Text fontSize={"15px"} >Add a new address</Text>
                            </Flex>
                        </Box>
                        <Box m={"16px 0px 0px"}>
                            <Text color={"#666666"} fontSize={"13px"}>Your saved address</Text>
                        </Box>
                        {
                            allAdd.map((ele, idx) => {
                                return (
                                    <Box key={idx} p={"12px"} m={"12px 0px"} bg={"white"} cursor={"pointer"}
                                        _hover={{ background: '#ebeced' }}

                                    >
                                        <Flex gap={"10px"} >
                                            <Box onClick={() => handleClickAddress(ele.id)}>
                                                {
                                                    ele.location_user === null ? <i class="fa-solid fa-house-user"></i> : null
                                                }
                                                {
                                                    ele.location_user === "Home" && <i className="fa-solid fa-home"></i>
                                                }
                                                {
                                                    ele.location_user === "Work" && <i class="fa-solid fa-briefcase"></i>
                                                }
                                                {
                                                    ele.location_user === "Other" && <i class="fa-solid fa-location-dot"></i>
                                                }
                                                {
                                                    ele.location_user === "Hotel" && <i className="fa-solid fa-building"></i>
                                                }
                                            </Box>
                                            <Box>
                                                <Text onClick={() => handleClickAddress(ele.id)} fontWeight={"600"} fontSize={"15px"}>{ele.location_user === null ? "Home" : ele.location_user}</Text>
                                                <Text onClick={() => handleClickAddress(ele.id)} color={"#828282"} fontSize={"12px"}>{ele.name},{ele.flatno + " " + ele.area}</Text>
                                                <Box borderRadius={"50%"} cursor={"pointer"} mt={"8px"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} w={"10%"} onClick={() => handleNavigate(ele.id)} ><i style={{ margin: "auto" }} class="fa-solid fa-pen fa-xs"></i></Box>
                                                <Box borderRadius={"50%"} cursor={"pointer"} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} w={"5%"} >

                                                    <Menu borderRadius="none">
                                                        <MenuButton as={Text} >
                                                            <Text >...</Text>
                                                        </MenuButton>
                                                        <MenuList borderRadius="none">
                                                            <Link to={`/single/${ele.id}`}>
                                                                <MenuItem>Edit</MenuItem>
                                                            </Link>
                                                            <MenuItem
                                                                onClick={() => handleDelete(ele.id)}
                                                            >Delete</MenuItem>
                                                        </MenuList>

                                                    </Menu>
                                                </Box>
                                            </Box>
                                        </Flex>
                                    </Box>
                                )
                            })
                        }
                    </DrawerBody>

                    <DrawerFooter>
                        {/* <Button variant='outline' mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue'>Save</Button> */}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size={drawerSize}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottom={"1px solid #eee"}>My Cart</DrawerHeader>

                    <DrawerBody background={"#eee"} p={"5px"}
                        flex="1" /* Takes all the remaining space */
                        overflowY="auto" /* Enables scrolling for overflow content */
                    >

                        {
                            cart.length > 0 ?
                                <Box >
                                    {
                                        cart.map((ele, idx) => {
                                            return (
                                                <Box mb={"10px"} key={idx} background={"white"} p={"10px"}>

                                                    <Flex justifyContent={"space-between"} alignItems={"center"}>
                                                        <Box>
                                                            <Flex gap={"20px"}>
                                                                <Box border={"1px solid gray"} borderRadius={"10px"} w={"30%"}
                                                                    h={"20%"}
                                                                >
                                                                    <Image
                                                                        borderRadius={"10px"}
                                                                        src={`https://bigbullpaisewala.co.in/${ele.product.product_image}`} />
                                                                </Box>
                                                                <Box>
                                                                    <Text fontSize={"12px"}>{ele.product.product_name}</Text>
                                                                    <Text mt={"6px"} fontSize={"12px"}>{ele.quantity}</Text>
                                                                    <Box mt={"6px"}>
                                                                        <Flex gap={"10px"}>
                                                                            <Text fontSize={"12px"} fontWeight={"bold"}>₹{ele.product.sell_price}</Text>
                                                                            <Text fontSize={"12px"} textDecoration={"line-through"}>₹{ele.product.mrp_price}</Text>
                                                                        </Flex>
                                                                    </Box>

                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                        <Box >
                                                            <Flex flexDirection={"column"} justifyContent={"space-between"}
                                                            // gap={"20px"}
                                                            >
                                                                <Box

                                                                // position={"absolute"}  
                                                                // top={"0"} right={"-5"} 


                                                                >
                                                                    <Box cursor={"pointer"}>
                                                                        <i style={{ float: "right" }}
                                                                            onClick={() => handleRemoveItem(ele.id)}
                                                                            className="fa-regular fa-trash-can"></i>

                                                                    </Box>
                                                                </Box>
                                                                <Box mt={"20px"} borderRadius={"10px"} >

                                                                    <Flex borderRadius={"10px"} bg={"#F91919"} color={"white"} alignItems={"center"}>
                                                                        <Button color={"white"} bg={"#F91919"} size={"sm"} _hover={{ backgroundColor: "#F91919", color: "white" }}
                                                                            onClick={() => handleDecrement(ele.id, ele.quantity)}
                                                                        >-</Button>
                                                                        <Text fontSize={"sm"}>{ele.quantity}</Text>
                                                                        <Button color={"white"} bg={"#F91919"} _hover={{ backgroundColor: "#F91919", color: "white" }} size={"sm"}
                                                                            onClick={() => handleIncrement(ele.id, ele.quantity)}
                                                                        >+</Button>
                                                                    </Flex>
                                                                </Box>
                                                            </Flex>

                                                        </Box>

                                                    </Flex>

                                                </Box>
                                            )
                                        })

                                    }
                                    <Box bg={"white"} mt={"20px"} borderRadius={"10px"} >
                                        <Text fontWeight={"bold"} p={"12px"}>Bill Details</Text>
                                        <Box p={"0px 12px 8px"}>
                                            <Flex justifyContent={"space-between"}>
                                                <Box>
                                                    <Flex alignItems={"center"} gap={"20px"}>
                                                        <Box><Flex alignItems={"center"} gap={"6px"}>
                                                            <i class="fa-solid fa-book" style={{ fontSize: "12px" }}></i>
                                                            <Text fontSize={"12px"}>Item Total</Text>
                                                        </Flex></Box>

                                                        <Text p={"2px 4px"} bg={"#EDF4FF"} color={"#256FEF"} fontSize={"9px"}>You saved </Text>
                                                    </Flex>
                                                </Box>
                                                <Box>
                                                    <Flex alignItems={"center"} gap={"5px"}>
                                                        <Text fontSize={"13px"} textDecoration={"line-through"}>₹{totalAmount1}</Text>
                                                        <Text fontSize={"13px"}>₹{totalAmount}</Text>
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box p={"0px 12px 8px"}>
                                            <Flex justifyContent={"space-between"}>
                                                <Box>
                                                    <Flex gap={"20px"} alignItems={"center"}>
                                                        <Box>
                                                            <Flex gap={"6px"} alignItems={"center"}>
                                                                <i class="fa-solid fa-motorcycle" style={{ fontSize: "12px" }}></i>
                                                                <Text fontSize={"12px"}>Delivery Charge</Text>
                                                            </Flex>
                                                        </Box>
                                                        {/* <Box>
                                        <i class="fa-solid fa-info" style={{ fontSize: "12px" ,border:"1px solid black",borderRadius:"50%",padding:"3px"}}></i>
                                        </Box> */}
                                                    </Flex>
                                                </Box>
                                                <Box>
                                                    <Flex alignItems={"center"} gap={"5px"}>
                                                        {/* <Text fontSize={"13px"} textDecoration={"line-through"}>100</Text> */}
                                                        <Text fontSize={"13px"} color={"#256FEF"}>₹{selectedAddress === "" ? 0 : deliveryPrice}</Text>
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box p={"0px 12px 8px"}>
                                            <Flex justifyContent={"space-between"}>
                                                <Box>
                                                    <Flex gap={"20px"} alignItems={"center"}>
                                                        <Box>
                                                            <Flex gap={"6px"} alignItems={"center"}>
                                                                <i class="fa-solid fa-bag-shopping" style={{ fontSize: "12px" }}></i>
                                                                <Text fontSize={"12px"}>Handling Charge</Text>
                                                            </Flex>
                                                        </Box>
                                                        {/* <Box>
                                        <i class="fa-solid fa-info" style={{ fontSize: "12px" ,border:"1px solid black",borderRadius:"50%",padding:"3px"}}></i>
                                        </Box> */}
                                                    </Flex>
                                                </Box>
                                                <Box>


                                                    <Text fontSize={"13px"} >₹{totalhandle}</Text>

                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box>

                                    <Box bg={"white"} mt={"20px"} borderRadius={"10px"} >
                                        <Text fontWeight={"bold"} p={"12px"}>Delivery</Text>
                                        <Box p={"0px 12px 8px"}>
                                            <Flex justifyContent={"space-between"}>
                                                <Box>
                                                    <Flex alignItems={"center"} gap={"20px"}>
                                                        <Box><Flex alignItems={"center"} gap={"6px"}>
                                                            <i class="fa-solid fa-book" style={{ fontSize: "12px" }}></i>
                                                            <Text fontSize={"12px"}>Pay on delivery</Text>
                                                        </Flex></Box>


                                                    </Flex>
                                                </Box>
                                                <Box>
                                                    <Flex alignItems={"center"} gap={"5px"}>
                                                        <Switch
                                                            size="sm"
                                                            colorScheme="red"
                                                            isChecked={true}
                                                            isReadOnly // Makes the switch unclickable
                                                        />
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>


                                    </Box>
                                </Box> : <Box>
                                    <Image src={emptycart} />
                                </Box>
                        }
                    </DrawerBody>

                    <DrawerFooter p={"5px"} bg={"#eee"} zIndex="100"
                        position="sticky" /* Sticks the footer to the bottom of the drawer */
                        bottom="0">
                        {
                            cart.length > 0 ? <Box borderTopLeftRadius={"5px"} borderTopRightRadius={"5px"} bg={"white"} w={"100%"}>
                                {addressget.length > 0 && selectedAddress && (
                                    <Box p={"5px 5px 0px 5px"}>
                                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                                            <Box>
                                                <i className="fa-solid fa-location-dot"></i>
                                            </Box>
                                            <Box>
                                                <Text fontWeight={"800"} color={"#000000"} fontSize={"13px"}>Delivering to home</Text>
                                                <Text fontSize={"11px"}>Address is: {selectedAddress.area.slice(0, 26)}</Text>
                                            </Box>
                                            <Box cursor={"pointer"}>
                                                <Text color={"#0C831F"} fontWeight={"bold"} fontSize={"12px"}
                                                    onClick={openModal1}
                                                >Change</Text>
                                            </Box>
                                        </Flex>
                                    </Box>
                                )}
                                {
                                    selectedAddress !== "" ? <Divider mt={"5px"} mb={"5px"} bg={"black"} /> : null
                                }
                                <Flex>

                                    <Box w={"100%"} bg={"#F91919"}
                                        onClick={handlechecknext}
                                        borderRadius={"10px"} p={"10px 12px"} color={"white"}>
                                        <Flex justifyContent={"space-between"} alignItems={"center"}>
                                            <Box>
                                                <Text fontWeight={"bold"} fontSize={"15px"}>₹{totalAmount + deliveryPrice + totalhandle}</Text>

                                                <Text fontSize={"11px"}>TOTAL</Text>
                                            </Box>
                                            <Box cursor={"pointer"}>
                                                <Flex alignItems={"center"} gap={"5px"}>
                                                    <Text fontWeight={"bold"} fontSize={"16px"} cursor={"pointer"}>PROCEED</Text>
                                                    <ChevronRightIcon></ChevronRightIcon>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Box> : null
                        }
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <header className="header">
                <div className="header__column">
                    <Link to="/" className="header__logo">
                        <img src={logo} alt='logo' />
                    </Link>
                </div>

                <nav className="header__column header__nav">
                    <ul>
                        <li>
                            <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                                <i className="fas fa-home"></i> <span>Home</span>
                            </Link>
                        </li>
                        <li>
                            {
                                isPaid && login ? (
                                    <Link
                                        to="/world"
                                        className={location.pathname === '/world' ? 'active' : ''}
                                    >
                                        <i className="fas fa-globe"></i> <span>Big World</span>
                                    </Link>
                                ) : (
                                    <Link
                                        onClick={handlepayfirst}
                                        className={`${location.pathname === '/world' ? 'active' : ''} link-button classbuttonbest`}
                                       
                                    >
                                        <i className="fas fa-globe"></i> <span>Big World</span>
                                    </Link>
                                )
                            }

                        </li>
                        {
                            login.type === "vendor" ?
                                <li>
                                    <div className="header-plus-icon" onClick={toggleTab}>
                                        <i className={`fa-solid ${isPlus ? 'fa-plus' : 'fa-xmark'}`}></i> {/* Conditional icon rendering */}
                                    </div>
                                </li> : null
                        }

                        <li>
                            {
                                login.type === "vendor" ?
                                    <Link to="/vendor-wallet" className={location.pathname === '/vendor-wallet' ? 'active' : ''}>
                                        <i className="fas fa-wallet"></i> <span>Wallet</span>
                                    </Link> :
                                    <Link to="/wallet" className={location.pathname === '/wallet' ? 'active' : ''}>
                                        <i className="fas fa-wallet"></i> <span>Wallet</span>
                                    </Link>
                            }
                        </li>

                        <li className='header-order'>
                            <Link to="/order" className={location.pathname === '/order' ? 'active' : ''}>
                                <i class="fa-solid fa-bag-shopping"></i> <span>Orders</span>
                            </Link>
                        </li>
                    </ul>
                </nav>

                <div className="header__column header__actions">
                    {/* <i className="fas fa-search header__icon" onClick={handleSearchClick}></i>
                    <i className="fas fa-envelope header__icon header__message-icon" onClick={handleMessagesClick}>
                        {unreadMessages > 0 && (
                            <span className="header__badge">{unreadMessages}</span>
                        )}
                    </i> */}
                    <i className="fas fa-search header__icon" onClick={handleSearchClick}></i>

                    <i class="fa-solid fa-cart-shopping header__icon header__message-icon" onClick={() => {
                        onOpen()
                        GetSingleStoreInCart()
                    }}>
                        {totalCartItem > 0 && (
                            <span className="header__badge">{totalCartItem}</span>
                        )}
                    </i>

                    {login === "" ? (
                        <button onClick={() => navigate('/login')} className="header__login-btn">Login</button>
                    ) : (

                        <div className="header__user">
                            {console.log(profileDetails.profilePic)}
                            {/* <img src={user.avatar} alt="User Avatar"  /> */}
                            <Menu>
                                <MenuButton  >
                                    <div className='user-profile-flex'>
                                        <img
                                            src={
                                                profileDetails.profilePic && profileDetails.profilePic.startsWith("https://lh3.googleusercontent.com")
                                                    ? profileDetails.profilePic
                                                    : `https://bigbullpaisewala.co.in/${profileDetails.profilePic || userimg}`
                                            }
                                            alt="User"
                                            className="header__user-avatar"
                                        />

                                        <span className='user-name'>{login.name === null ? login.type : login.name}</span>


                                        <i className="fas fa-chevron-down"></i>
                                    </div>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => navigate("/user-profile")}>My Profile</MenuItem>
                                    {
                                        login.type === "vendor" ? <MenuItem onClick={() => navigate("/vendor-order")}>New orders</MenuItem> : null
                                    }
                                    <MenuItem onClick={() => navigate("/order")}>Purchase history</MenuItem>
                                    <MenuItem onClick={() => navigate("/ads")}>My Ads</MenuItem>
                                    {/* <p className='profile-drawer-setting'>Account Setting</p> */}
                                    <MenuItem onClick={handleDeleteAccount}>Delete account</MenuItem>
                                    <MenuItem>
                                        <Link to='/reset-password'>
                                            Reset Password
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handlelogout}>Logout</MenuItem>
                                </MenuList>
                            </Menu>
                        </div>
                    )}
                </div>
            </header>

            {isTabOpen && (
                <div className="header-tab">
                    <div
                        className={`header-tab__item ${activeTab === 'store-listing' ? 'active' : ''}`}
                        onClick={() => handleTabClick('store-listing')}
                    >

                        {storeMain.status === "Active" ? (
                            <span onClick={() => navigate(`/detail-listing/${storeMain.id}/${storeMain.businessDetails.businessName}`)}>
                                <i className="fas fa-store"></i><p> Go to Store</p>
                            </span>
                        ) : <span onClick={() => navigate("/store-listing")}>
                            <i className="fas fa-store"></i><p> Add Store</p>
                        </span>}
                    </div>
                    <div
                        className={`header-tab__item ${activeTab === 'product-listing' ? 'active' : ''}`}
                        onClick={() => handleTabClick('product-listing')}
                    >
                        <span onClick={handleCheckProduct} >
                            <i className="fas fa-box" ></i> <p>Add Product</p>
                        </span>
                        {activeTab === 'product-listing' && (
                            <div className="header-tab__content">
                                <Link to="/product">Go to Product Listing</Link>
                            </div>
                        )}
                    </div>
                    <div
                        className={`header-tab__item ${activeTab === 'advertise' ? 'active' : ''}`}
                        onClick={() => handleTabClick('advertise')}
                    >
                        <span onClick={() => navigate("/ads")}>
                            <i className="fas fa-bullhorn"></i> <p>My Ads</p>
                        </span>
                        {activeTab === 'advertise' && (
                            <div className="header-tab__content">
                                <Link to="/advertise">Go to Advertise</Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;
