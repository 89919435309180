import '../CSS/Global.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import img1 from '../Images/fruits-main.webp';

const DetailListImg = () => {
    const images = [img1, img1, img1, img1, img1, img1]; // Example image array

    const renderImages = () => {
        const imgCount = images.length;

        return (
            <div className="image-wrapper">
                {imgCount <= 1 ? (
                    <div className="single-page-img1">
                        <div className="img-container img-1">
                            <img src={images[0]} alt="Single Image" />
                        </div>
                    </div>
                ) : imgCount === 2 ? (
                    <div className="single-page-img2">
                        {images.map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                ) : imgCount === 3 ? (
                    <div className="single-page-img3">
                        {images.map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                ) : imgCount === 4 ? (
                    <div className="single-page-img4">
                        {images.map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                ) : imgCount === 5 ? (
                    <div className="single-page-img5">
                        {images.map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="single-page-img6">
                        {images.slice(0, 5).map((img, index) => (
                            <div className={`img-container img-${index + 1}`} key={index}>
                                <img src={img} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                        <div className="img-container img-6">
                            <div className="overlay">{`${imgCount - 5}+ more images`}</div>
                        </div>
                    </div>
                )}
                <div className="add-photos-btn-wrapper">
                    <input type="file" id="common-file-input" className="common-file-input" multiple />
                    <label htmlFor="common-file-input" className="add-photos-btn">
                        + Add Photos
                    </label>
                </div>
            </div>
        );
    };

    let swiperInstance = null;

    return (
        <>
            <div className="single-page-img">{renderImages()}</div>
            <div className="single-page-img-mob">
                <Swiper
                    modules={['Autoplay']} // Using 'Autoplay' directly in the modules array
                    autoplay={{
                        delay: 2500, // Slide every 2.5 seconds
                        disableOnInteraction: false, // Keep autoplay running after interaction
                    }}
                    slidesPerView={1}
                    spaceBetween={10}
                    loop={true}
                    onSwiper={(swiper) => { swiperInstance = swiper; }} // Capture swiper instance
                    onMouseEnter={() => swiperInstance.autoplay.stop()} // Stop autoplay on hover
                    onMouseLeave={() => swiperInstance.autoplay.start()} // Restart autoplay when mouse leaves
                >
                    {images.map((img, index) => (
                        <SwiperSlide key={index}>
                            <div className="img-slider-mob">
                                <img src={img} alt={`Slide ${index + 1}`} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <button className="add-photos-btn-mobile">+ Add images</button>
            </div>
        </>
    );
};

export default DetailListImg;
