import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import '../CSS/Global.css';
import axios from 'axios';
import { Button, Image, Spinner, Text, useDisclosure, useEditable, useToast } from '@chakra-ui/react';
import {
    login as loginGoogle,
    logout as logoutGoogle
} from "webtonative/SocialLogin/google";
import logofr from "../Images/g-logo.png"

const Register = () => {
    const navigate = useNavigate(); // Initialize navigate for navigation
    const emailsaveregister = localStorage.getItem("emailsavedregister")
    const [isVendor, setIsVendor] = useState();
    const [isuser, setISuser] = useState();
    const [email, setEmail] = useState(emailsaveregister ? emailsaveregister : "");
    const [otpDigits, setOtpDigits] = useState(Array(6).fill(''));
    const [password, setPassword] = useState('');
    const [name, setName] = useState(''); // Added name state for user
    const [businessName, setBusinessName] = useState('');
    const [address, setAddress] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [category, setCategory] = useState(''); // Added category state for vendor
    const [subcategory, setSubcategory] = useState(''); // Added subcategory state for vendor
    const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [resendClicked, setResendClicked] = useState(false);
    const [type, setType] = useState("");
    const [otp, setOtp] = useState('');
    const dummy = JSON.parse(localStorage.getItem("dummy")) || ""
    const login = JSON.parse(localStorage.getItem("login")) || ""
    const [amount, setAmount] = useState("")
    const toast = useToast()
    const [load, setLoad] = useState(true);
    const [load1, setLoad1] = useState(true);
    const [load2, setLoad2] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [resendCooldown, setResendCooldown] = useState(0);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isNativeApp, setIsNativeApp] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    const handleRegisterTypeChange = (event) => {
        const type = event.target.value;
        console.log(type)
        setIsVendor(type === 'vendor');
        setISuser(type === 'user')
        setType(type);
        resetFields();
    };

    console.log(isVendor)
    const resetFields = () => {
        // setEmail('');
        setOtpDigits(Array(6).fill(''));
        setPassword('');
        setName(''); // Reset name field
        setBusinessName('');
        setContactNumber('');
        setIsOtpModalOpen(false);
        setOtpVerified(false);
    };

    const handleEmailSubmit = async () => {
        setLoad(false);
        if (email === "" || type === "") {
            // console.log("hi");
            toast({
                description: `Enter all details`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
            setLoad(true);
            // alert(")
            return;
        } else {
            // setIsOtpModalOpen(true);
            localStorage.setItem("dummy", JSON.stringify({ email, type }));
            try {
                const response = await axios.post("https://bigbullpaisewala.co.in/register/api/send-otp", { "email": email });
                if (response.data.success) {
                    console.log('OTP sent to:', email);
                    // handleShow8();
                    setIsOtpModalOpen(true);
                    toast({
                        description: `OTP sent to: ${email}`,
                        status: 'success',
                        duration: 5000,
                        position: 'top-center',
                        isClosable: true,
                    });
                    setLoad(true);
                } else {
                    setLoad(true);
                    toast({
                        description: `${response.data.message}`,
                        status: 'warning',
                        duration: 5000,
                        position: 'top-center',
                        isClosable: true,
                    });
                }
            } catch (error) {
                // alert(error.response.data.message);
                toast({
                    description: `${error.response.data.message}`,
                    status: 'warning',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
                setLoad(true);
                console.error("Sign-Up Error:", error);
            }
        }
        // Implement actual OTP API call
    };

    const handleOtpChange = (index, value) => {
        let newOtp = otp.split('');
        newOtp[index] = value.replace(/\D/g, '');
        setOtp(newOtp.join(''));

        if (value && index < 5) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    const fetchDataPrice = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/registerpay`);
            const res = await response.json();
            setAmount(res.data[0].register_price);
        } catch (error) {
            console.log(error);
        }
    };


    const handleOtpVerification = async () => {
        // const otp = otpDigits.join('');
        // console.log('OTP verified:', otp);
        setLoad1(false);
        try {
            const response = await axios.post("https://bigbullpaisewala.co.in/register/api/verify-otp", {
                email: dummy.email,
                otp: otp
            })

            if (response.data.success) {
                setOtpVerified(true);
                setIsOtpModalOpen(false);
                toast({
                    description: `OTP verified`,
                    status: 'success',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
                setLoad1(true);
            }
            console.log(response);
        } catch (error) {
            toast({
                description: `Invalid OTP`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
            setLoad1(true);
        }

    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
        }
    };

    const handleOtpPaste = (e) => {
        const pasteData = e.clipboardData.getData('text').slice(0, 6);
        if (pasteData) {
            setOtp(pasteData);
            pasteData.split('').forEach((value, index) => {
                if (document.getElementById(`otp-${index}`)) {
                    document.getElementById(`otp-${index}`).value = value;
                }
            });
        }
    };

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        console.log("User Agent:", userAgent);

        if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
            console.log("Detected Android or iOS device");
            setIsNativeApp(true);
        } else {
            setIsNativeApp(false);
        }
    }, []);


    const handleResendOtp = async () => {
        setResendClicked(true);
        if (resendCooldown > 0) return;
        console.log('Resending OTP to:', email);
        try {
            const response = await axios.post("https://bigbullpaisewala.co.in/register/api/resend-otp", { "email": email });
            if (response.data.success) {
                console.log('OTP sent to:', email);
                // handleShow8();
                setIsOtpModalOpen(true);
                toast({
                    description: `OTP sent to: ${email}`,
                    status: 'success',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
                setResendCooldown(180)
                // setLoad(true);
            } else {
                // setLoad(true);
                toast({
                    description: `${response.data.message}`,
                    status: 'warning',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
            }
        } catch (error) {
            // alert(error.response.data.message);
            toast({
                description: `${error.response.data.message}`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
            // setLoad(true);
            console.error("Sign-Up Error:", error);
        }

    };

    const handleGoogleAuthSuccess = async (response) => {
        console.log('Google authentication success:', response);
        if (type === "") {
            toast({
                description: `Please choose your account-type`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
            return
        } else {
            try {
                const res = await axios.post("https://bigbullpaisewala.co.in/register/api/google-signup", {
                    token: response.credential,
                    type
                });
                console.log(res);
                if (res.data.success) {
                    console.log(res.data.users);
                    navigate("/");
                    localStorage.setItem("login", JSON.stringify(res.data.users));
                    window.location.reload();
                    toast({
                        description: `Welcome ${res.data.users.name}`,
                        status: 'success',
                        duration: 5000,
                        position: 'top-center',
                        isClosable: true,
                    });

                } else {
                    alert(res.data.message);
                }
            } catch (error) {
                console.log("Google Sign-Up Error:", error.response ? error.response.data : error.message);
            }
        }
    };

    const handleLogingoggle = () => {
        if (isNativeApp) {
            // Trigger login via webtonative for native apps
            loginGoogle({
                callback: async (value) => {
                    if (type === "") {
                        toast({
                            description: `Please choose your account-type`,
                            status: 'warning',
                            duration: 5000,
                            position: 'top-center',
                            isClosable: true,
                        });
                        return
                    } else {
                        try {
                            const res = await axios.post("https://bigbullpaisewala.co.in/register/api/google-signup", {
                                token: value.idToken,
                                type
                            });
                            console.log(res);
                            if (res.data.success) {
                                console.log(res.data.users);
                                navigate("/");
                                localStorage.setItem("login", JSON.stringify(res.data.users));
                                window.location.reload();
                                toast({
                                    description: `Welcome ${res.data.users.name}`,
                                    status: 'success',
                                    duration: 5000,
                                    position: 'top-center',
                                    isClosable: true,
                                });

                            } else {
                                alert(res.data.message);
                            }
                        } catch (error) {
                            toast({
                                description: `${error.message}`,
                                status: 'warning',
                                duration: 8000,
                                position: 'top-center',
                                isClosable: true,
                            });
                            console.log("Google Sign-Up Error:", error.response ? error.response.data : error.message);
                        }
                    }
                },
            });
        } else {
            // Handle Google Login for the website
            console.log("Redirecting to Google Login for website...");
            toast({
                description: `Redirecting to Google Login for website...`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
        }
    };
    const handleGoogleAuthFailure = (error) => {
        console.error('Google authentication error:', error);
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    };

    const handleClick = async (log) => {
        try {
            let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

            // Load the Razorpay SDK
            let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                alert("Razorpay SDK Failed to load. Are you online?");
                return;
            }

            // Define payment details
            let paymentDetails = {

                user_id: log.permanent_ID,
                user_phone: log.name,
                transaction_id: orderId,
                amount: amount * 100,
                currency: "INR",
                payment_capture: 1,
                payment_mode: "online",
            };

            // Create order options
            let options = {
                key: "rzp_live_Xe3FsoiFgJrvDM",
                currency: paymentDetails.currency,
                amount: paymentDetails.amount,
                name: "Big Bull Paisewala",
                description: "Transaction",
                handler: async function (response) {

                    if (response.razorpay_payment_id) {
                        // Payment was successful
                        paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
                        paymentDetails.status = 'Paid';
                        let result = await axios.post(`https://bigbullpaisewala.co.in/payments`, paymentDetails);

                        if (!result.data.isError) {
                            // Redirect to the success page
                            //   checkPaymentStatus();
                            navigate("/")
                            window.location.reload();
                            // window.location.href = `/delivery/${orderId}`; // Replace "/success-page" with the URL of your success page
                        } else {
                            alert("Server error. Are You Online");
                            //   window.location.href = "/error-page"; // Redirect to an error page
                        }
                    } else {
                        // Handle payment failure or cancellation

                        paymentDetails.status = 'Failed';
                        await axios.post(`https://bigbullpaisewala.co.in/payments`, paymentDetails);
                        navigate("/")
                        // window.location.href = "/error-page"; // Redirect to an error page
                    }
                },
                prefill: {
                    email: "bigbullbusinesssolutionspvtltd@gmail.com",
                    contact: "7757855388"
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#f8402a"
                },
                modal: {
                    ondismiss: function () {
                        localStorage.setItem("login", JSON.stringify(log))
                        navigate("/")
                    },
                },
            };

            // Initialize Razorpay payment object and open checkout
            let paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {
                paymentDetails.status = 'Failed';
                axios.post(`https://bigbullpaisewala.co.in/payments`, paymentDetails)
                    .then(() => {
                        navigate("/") // Redirect to an error page
                    }).catch(error => {
                        navigate("/") // Redirect to an error page
                    });
            });

            paymentObject.open();

        } catch (error) {
            console.log(error);
        }
    };




    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoad2(false);
        try {
            const response = await axios.post("https://bigbullpaisewala.co.in/register/api/signup", {
                email: dummy.email,
                type: dummy.type,
                mobile: contactNumber,
                name: businessName,
                password: password,
                // otp: otp
            })

            if (response.data.success) {
                console.log(response.data.newUser);
                localStorage.setItem("login", JSON.stringify(response.data.newUser))
                handleClick(response.data.newUser);
                // navigate("/")
                toast({
                    title: 'Registered Successfully',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                setLoad2(true);
            }
            console.log(response);
        } catch (error) {
            console.log(error)
            toast({
                description: `${error.response.data.message}`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
            setLoad2(true);
        }
        console.log('Registered:', {
            email,
            password,
            ...(isVendor && {
                businessName,
                contactNumber,
            }),
            ...(name && { name }), // Include name if it's a user
        });
    };

    useEffect(() => {
        fetchDataPrice();
    }, [])

    useEffect(() => {
        if (resendCooldown > 0) {
            const timer = setTimeout(() => setResendCooldown(resendCooldown - 1), 1000);
            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, [resendCooldown]);

    console.log(otp)
    return (
        <>


            {/* <Button onClick={onOpen}>Open Modal</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Modal content goes here.</ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}

            <div className='form-component'>
                <div className="form-flex">
                    <div className="left-flex">
                    </div>
                    {/* <Button bg={"red"} color={"white"} _hover={{ background: "#d30507", color: "white", fontWeight: "600" }}
                        onClick={openModal5}
                    >Open</Button> */}
                    <div className="auth-options">
                        <div className="google-auth">
                            <GoogleOAuthProvider clientId="377533277654-mecoas8jv5r22jk5cerltilnfisp0dcu.apps.googleusercontent.com">
                                <div className="register-container">
                                    <h2>Create Account</h2>
                                    <div className="radio-container">
                                        <label>
                                            <input
                                                type="radio"
                                                value="user"
                                                checked={isuser}
                                                onChange={handleRegisterTypeChange}
                                            />
                                            <span className="custom-radio"></span>
                                            User
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                value="vendor"
                                                checked={isVendor}
                                                onChange={handleRegisterTypeChange}
                                            />
                                            <span className="custom-radio"></span>
                                            Vendor
                                        </label>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        {!otpVerified && (
                                            <>
                                                {
                                                    load ? <button type="button" className='form-button' onClick={handleEmailSubmit}>
                                                        Send OTP
                                                    </button> : <button type="button" className='form-button' ><Spinner /></button>
                                                }
                                                <Modal isOpen={isOtpModalOpen} onRequestClose={() => setIsOtpModalOpen(false)} className="otp-modal">
                                                    <h2>Verify OTP</h2>
                                                    <p>Check your email.</p>
                                                    <p>We've sent you a code to <b>{email}</b></p>

                                                    <div className="otp-inputs" onPaste={handleOtpPaste}>
                                                        {[...Array(6)].map((_, index) => (
                                                            <input
                                                                key={index}
                                                                id={`otp-${index}`}
                                                                type="text"
                                                                maxLength="1"
                                                                value={otp[index] || ''}
                                                                // style={{color:"black"}}
                                                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                                                onKeyDown={(e) => handleKeyDown(index, e)}
                                                            />
                                                        ))}
                                                    </div>

                                                    <p className="resend-code">
                                                        Didn’t get the code?{' '}
                                                        <span
                                                            onClick={resendCooldown === 0 ? handleResendOtp : null}
                                                            className={resendCooldown === 0 ? "resend-link active" : "resend-link disabled"}
                                                        >
                                                            {resendCooldown > 0 ? `Resend in ${resendCooldown}s` : 'Click to resend'}
                                                        </span>
                                                    </p>

                                                    {
                                                        load1 ? <button type="button" className='form-button' onClick={handleOtpVerification}>
                                                            Verify OTP
                                                        </button> : <button type="button" className='form-button' >
                                                            <Spinner />
                                                        </button>
                                                    }
                                                </Modal>
                                            </>
                                        )}

                                        {!otpVerified && (
                                            <>
                                                <div className="auth-options">
                                                    {isNativeApp ? (
                                                        <Button
                                                            onClick={handleLogingoggle}
                                                            bg="white" // Background color
                                                            color="black" // Text color
                                                            fontWeight="500" // Bold text
                                                            borderRadius="md" // Medium border radius
                                                            border="1px solid lightgray" // Optional border
                                                            padding="8px 24px" // Padding for size
                                                            _hover={{ background: "#f1f1f1", transform: "scale(1.05)" }} // Hover effects
                                                            boxShadow="lg" // Optional shadow for elevation
                                                            mt={4}
                                                        >
                                                            <Image
                                                                src={logofr}
                                                                alt="Google Logo"
                                                                boxSize="20px"
                                                                mr={2}
                                                            />
                                                            Sign in with Google
                                                        </Button>) : (
                                                        <GoogleLogin
                                                            onSuccess={handleGoogleAuthSuccess}
                                                            onError={handleGoogleAuthFailure}
                                                        />
                                                    )}
                                                </div>
                                                <p className='already'>
                                                    Already have an account?
                                                    <span onClick={() => navigate('/login')} className="login-link"> Login here</span>
                                                </p>
                                            </>
                                        )}

                                        {otpVerified && (
                                            <>
                                                {!isVendor ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            placeholder="Name"
                                                            value={businessName}
                                                            onChange={(e) => setBusinessName(e.target.value)}
                                                            required
                                                        />
                                                        <div className="password-container">
                                                            <input
                                                                type={isPasswordVisible ? "text" : "password"}
                                                                placeholder="Password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                required
                                                            />
                                                            <button
                                                                type="button"
                                                                id="toggle-password-button"
                                                                onClick={togglePasswordVisibility}
                                                            >
                                                                {isPasswordVisible ? (
                                                                    <i className="fa-solid fa-eye-slash"></i>
                                                                ) : (
                                                                    <i className="fa-solid fa-eye"></i>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="text"
                                                            placeholder="Business Name"
                                                            value={businessName}
                                                            onChange={(e) => setBusinessName(e.target.value)}
                                                            required
                                                        />

                                                        <input
                                                            type="text"
                                                            placeholder="Contact Number"
                                                            value={contactNumber}
                                                            onChange={(e) => setContactNumber(e.target.value)}
                                                            required
                                                        />


                                                        <input
                                                            type="password"
                                                            placeholder="Password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            required
                                                        />

                                                    </>
                                                )}

                                                {
                                                    load2 ? <button type="submit" className='form-button'>Register and Pay ₹{amount}</button> :
                                                        <button type="submit" className='form-button'><Spinner /></button>
                                                }

                                            </>
                                        )}
                                    </form>
                                </div>
                            </GoogleOAuthProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
