import React, { useEffect, useState } from 'react';
import '../CSS/Global.css'; // Ensure your CSS file path is correct
import { useNavigate, useParams } from 'react-router-dom';
import productadd from "../Images/productadd.png"

const EditProduct = () => {
    const [productName, setProductName] = useState('');
    const [mrp, setMrp] = useState('');
    const [sellingPrice, setSellingPrice] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [image, setImage] = useState();
    const login = JSON.parse(localStorage.getItem("login")) || "";
    let navigate = useNavigate();
    const { id } = useParams();
    const [selectedUnit, setSelectedUnit] = useState('');
    const [quantity, setSelectedQuantity] = useState('');
    const [units, setUnits] = useState([]);

    console.log(id);

    const handleImageUpload = (event) => {
        const file = event.target.files[0]; // Get the first file from input
        setImage(file); // Store the selected image in state
    };

    // Add form submission logic here

    const fetchDataProduct = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/product/getsingleproduct/${id}`)
            const res = await response.json();
            console.log(res);
            setProductName(res.product_name);
            setSellingPrice(res.sell_price);
            setMrp(res.mrp_price);
            setShortDescription(res.description);
            setImage(res.product_image);
            setSelectedQuantity(res.quantity);
            setSelectedUnit(res.unit)
        } catch (error) {
            console.log(error);
        }
    }

    console.log(image)
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Create FormData object to send image along with other data
        const formData = new FormData();
        formData.append('product_image', image);
        formData.append('product_name', productName);
        formData.append('mrp_price', mrp);
        formData.append('sell_price', sellingPrice);
        formData.append('quantity', quantity);
        formData.append('unit', selectedUnit);
        formData.append('description', shortDescription);
        formData.append('user_id', login.permanent_ID);
        formData.append('user_name', login.name);

        try {
            // Replace with your backend API URL
            const response = await fetch(`https://bigbullpaisewala.co.in/product/change-product/${id}`, {
                method: 'PUT',
                body: formData,
            });

            const result = await response.json();
            console.log('Image uploaded:', result);
            if (response.ok) {
                navigate(-1)
            }
        } catch (error) {
            console.log('Error uploading image:', error);
        }
        console.log({
            productName,
            mrp,
            sellingPrice,
            shortDescription,
        });
    };

    useEffect(() => {
        fetchDataProduct();
    }, [])

    return (
        <div className="product-container">
            <div className="product-image-column">
                <img
                    src={productadd}
                    alt="Product"
                    className="product-image"
                />
            </div>
            <div className="product-form-column">
                <h2>List Your Product</h2>
                <form onSubmit={handleSubmit}>
                    <div className="product-form-group">
                        <label>Product Name:</label>
                        <input
                            type="text"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            placeholder="Enter product name"
                            required
                        />
                    </div>
                    <div className="product-form-group">
                        <label>Upload Product Image:</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            required
                        />
                        <div>
                            <img className='imageeditproductmain' src={`https://bigbullpaisewala.co.in/${image}`} />
                        </div>
                    </div>
                    <div className="price-container">
                        <div className="product-form-group">
                            <label>Unit:</label>
                            <select
                                className='bestselecttagforec'
                                value={selectedUnit}
                                onChange={(e) => setSelectedUnit(e.target.value)}
                                required
                            >
                                <option value="">Select Unit</option>
                                {units.map((unit, index) => (
                                    <option key={index} value={unit}>
                                        {unit}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="product-form-group">
                            <label>Quantity/Value {selectedUnit === "" ? null : ("(" + selectedUnit + ")")}:</label>
                            <input
                                type="quantity"
                                value={quantity}
                                onChange={(e) => setSelectedQuantity(e.target.value)}
                                placeholder={`Enter Value in ${selectedUnit}`}
                                required
                            />
                        </div>

                    </div>

                    <div className="product-form-group">
                        <label>Short Description (max 50 chars):</label>
                        <input
                            type="text"
                            value={shortDescription}
                            onChange={(e) => setShortDescription(e.target.value)}
                            maxLength="50"
                            placeholder="Enter short description"
                            required
                        />
                    </div>
                    <div className="price-container">
                        <div className="product-form-group">
                            <label>MRP:</label>
                            <input
                                type="number"
                                value={mrp}
                                onChange={(e) => setMrp(e.target.value)}
                                placeholder="Enter MRP"
                                required
                            />
                        </div>
                        <div className="product-form-group">
                            <label>Selling Price:</label>
                            <input
                                type="number"
                                value={sellingPrice}
                                onChange={(e) => setSellingPrice(e.target.value)}
                                placeholder="Enter Selling Price"
                                required
                            />
                        </div>
                    </div>
                    <button type="submit" className="product-submit-button">Edit Product</button>
                </form>
            </div>
        </div>
    );
};

export default EditProduct;
