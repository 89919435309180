import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';
import Header from './Header';
import track from '../Images/track.webp';
import { useNavigate, useParams } from 'react-router-dom';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useDisclosure,
} from '@chakra-ui/react';

const VendorOrder = () => {
    const [activeStep, setActiveStep] = useState(1); // Default active step: "order placed"
    const steps = ["order placed", "dispatch", "out for delivery", "delivered"];
    const statusToStep = {
        "Order Placed": 1,
        "Dispatch": 2,
        "Out for Delivery": 3,
        "Delivered": 4,
    };
    const stepToStatus = ["Order Placed", "Dispatch", "Out for Delivery", "Delivered"];
    const { id } = useParams();
    const [order, setOrder] = useState({});
    const [orderprod, setOrderProd] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalStrikeOffPrice, setTotalStrikeOffPrice] = useState(0);
    const [addresspro, setAddressPro] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Fetch Order Data
    const fetchOrderData = async () => {
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/cart-orders/order/${id}`);
            const res = await response.json();

            const parsedOrder = {
                ...res.data,
                cart_product: JSON.parse(res.data.cart_product || "[]"),
                user_address: JSON.parse(res.data.user_address || "{}"),
                vendor_address: JSON.parse(res.data.vendor_address || "{}"),
            };

            setOrder(parsedOrder);
            setOrderProd(parsedOrder.cart_product);
            setAddressPro(parsedOrder.user_address);

            let totalPrice = 0;
            let totalStrikeOffPrice = 0;
            parsedOrder.cart_product.forEach((product) => {
                totalPrice += product.product.mrp_price * product.quantity;
                totalStrikeOffPrice += product.product.sell_price * product.quantity;
            });

            setTotalPrice(totalPrice);
            setTotalStrikeOffPrice(totalStrikeOffPrice);
            const step = statusToStep[parsedOrder.status] || 1;
            setActiveStep(step);
        } catch (error) {
            console.error(error);
        }
    };

    // Handle Status Change
    const handleChange = async (step) => {
        const newStatus = stepToStatus[step - 1];
        try {
            const response = await fetch(`https://bigbullpaisewala.co.in/cart-orders/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ status: newStatus }),
            });

            if (response.ok) {
                setActiveStep(step);
                setOrder((prevOrder) => ({
                    ...prevOrder,
                    status: newStatus,
                }));
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchOrderData();
    }, []);

    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>Invoice</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Download Invoice Coming Soon!
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialog>
            <Header />
            <div className="track">
                <img src={track} alt="Track your order" />
            </div>
            <div className="track-mapping">
                <div className="track-order-number">
                    <p className="order-id">Order #{order.transaction_id}</p>
                </div>
                <div className="mapping">
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`step 
                                    ${index + 1 < activeStep ? "active" : ""}
                                    ${index + 1 === activeStep ? "active pulse" : ""}
                                `}
                                onClick={() => {
                                    if (index + 1 === activeStep + 1) {
                                        handleChange(index + 1);
                                    }
                                }}
                            >
                                <span className="dot"></span>
                                <p>{step}</p>
                            </div>
                            {index < steps.length - 1 && <div className="line"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="track-orders-list">
                <div className="ordered-items">Ordered Items</div>
                {orderprod.map((product, index) => (
                    <div className="item-list" key={index}>
                        <div className="ordered-item-img">
                            <img
                                src={`https://bigbullpaisewala.co.in/${product.product.product_image}`}
                                alt={product.product.product_name}
                            />
                        </div>
                        <div className="ordered-item-detail">
                            <p className="product-name">{product.product.product_name}</p>
                            <p className="actual-amount">
                                <span className="line-through">₹{product.product.mrp_price.toFixed(2)}</span>
                                <span className="percent-discount">
                                    {Math.round(
                                        ((product.product.mrp_price - product.product.sell_price) /
                                            product.product.mrp_price) *
                                            100
                                    )}
                                    % Off
                                </span>
                            </p>
                            <div className="discount-quantity">
                                <p className="discount-amount">₹{product.product.sell_price.toFixed(2)}</p>
                                <p className="quantity">{product.quantity} Qty</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="bill-details">
                <div className="bill-details-header">Bill Details</div>
                <div className="bill-saved">
                    You saved ₹{(totalPrice - totalStrikeOffPrice).toFixed(2)} on this order
                </div>
                <div className="mrp-div">
                    <p className="mrp-title">MRP</p>
                    <p className="mrp">₹{totalPrice.toFixed(2)}</p>
                </div>
                <div className="discount-div">
                    <p className="discount-title">Discount</p>
                    <p className="discount">-₹{(totalPrice - totalStrikeOffPrice).toFixed(2)}</p>
                </div>
                <div className="delivery-div">
                    <p className="delivery-title">Delivery Charges</p>
                    <p className="delivery-charge">₹{order.delivery_amount || 0.0}</p>
                </div>
                <div className="bill-total">
                    <p className="payable">Payable</p>
                    <p className="total-amt">
                        ₹{(totalStrikeOffPrice + (order.delivery_amount || 0.0)).toFixed(2)}
                    </p>
                </div>
                <div className="payment-mode">
                    <p className="payment-mode-title">Payment Mode</p>
                    <p className="type">{order.payment_mode}</p>
                </div>
                <div className="download-invoice">
                    {/* <button className="download" onClick={onOpen}>
                        Download Invoice
                    </button> */}
                </div>
            </div>
        </>
    );
};

export default VendorOrder;
