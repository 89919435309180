import React, { useEffect, useState } from 'react';
import '../CSS/Global.css'; // Adjust the path if needed
import { Link, useNavigate, useParams } from 'react-router-dom';

const Gallery = () => {
  // Array of images (using Lorem Picsum for now)
  const images = [
    'https://picsum.photos/id/1015/400/300',
    'https://picsum.photos/id/1016/400/300',
    'https://picsum.photos/id/1018/400/300',
    'https://picsum.photos/id/1021/400/300',
    'https://picsum.photos/id/1022/400/300',
    'https://picsum.photos/id/1025/400/300',
    'https://picsum.photos/id/1024/400/300',
    'https://picsum.photos/id/1029/400/300',
    'https://picsum.photos/id/1031/400/300',
    'https://picsum.photos/id/1033/400/300'
  ];
  const { id, name } = useParams()
  const navigate = useNavigate()
  const login = JSON.parse(localStorage.getItem("login")) || ""
  // State for the carousel (tracking the current index)
  const [currentImage, setCurrentImage] = useState(0);

  // State to track whether the gallery is open or closed
  const [isGalleryOpen, setIsGalleryOpen] = useState(true);

  // Handle carousel navigation
  const nextImage = () => {
    setCurrentImage((prevIndex) => (prevIndex + 1) % (storeSingle.formImages?.length || images.length));
  };

  const prevImage = () => {
    setCurrentImage((prevIndex) =>
      prevIndex === 0 ? (storeSingle.formImages?.length || images.length) - 1 : prevIndex - 1
    );
  };

  // Handle closing the gallery
  const closeGallery = () => {
    navigate(-1)
  };

  const [storeSingle, setSinglevendor] = useState({})
  const detailVendorGet = async () => {
    try {
      const response = await fetch(`https://bigbullpaisewala.co.in/store/getstorelist/${id}/${name}`)
      const res = await response.json();
      console.log(res);
      const parsedData = {
        ...res,
        additionalEmails: JSON.parse(res.additionalEmails || "[]"),
        additionalLandlines: JSON.parse(res.additionalLandlines || "[]"),
        additionalMobiles: JSON.parse(res.additionalMobiles || "[]"),
        additionalServices: JSON.parse(res.additionalServices || "[]"),
        additionalWhatsapps: JSON.parse(res.additionalWhatsapps || "[]"),
        businessDetails: JSON.parse(res.businessDetails || "{}"),
        businessTiming: JSON.parse(res.businessTiming || "{}"),
        contactDetails: JSON.parse(res.contactDetails || "{}"),
        formImages: JSON.parse(res.formImages || "[]"),
        paymentDetails: JSON.parse(res.paymentDetails || "{}"),
        servicesDetails: JSON.parse(res.servicesDetails || "{}"),
    };
      setSinglevendor(parsedData);

    } catch (error) {
      console.log(error)
    }
  }

  const handleWhatsAppClick = (storeName, mobile) => {
    const message = `Hi, I would like to inquire about ${storeName}`;
    const phoneNumber = `+91${mobile}`; // Replace with the store's WhatsApp number
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleShareClick = (name, number, idN) => {
    const shareData = {
      title: "Check this out!",
      text: `Visit our website for more details! https://bigbullpaisewala.co.in/detail-listing/${idN}/${name}`,
      url: `https://bigbullpaisewala.co.in/detail-listing/${idN}/${name}` // Replace with your website URL
    };

    // Check if the browser supports the navigator.share API
    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Successfully shared'))
        .catch((error) => console.error('Error sharing', error));
    } else {
      // Fallback: Copy the link to the clipboard for desktop users
      navigator.clipboard.writeText(shareData.text)
        .then(() => alert('Link copied to clipboard!'))
        .catch((error) => console.error('Could not copy link', error));
    }
  };

  useEffect(() => {
    detailVendorGet();
  }, [])

  // Only render the gallery if it's open
  return (
    <>
      {isGalleryOpen && (
        <div className="gallery">
          <div className='gallery-info'>
            <div className='gallery-business-name'>
              {storeSingle.businessDetails && storeSingle.businessDetails.businessName}
            </div>
            <div className='gallery-contact-btn'>
              <Link to={`tel:${storeSingle.contactDetails && storeSingle.contactDetails.mobile}`}>
                <button className='contact-gallery'><i className="fa-solid fa-phone"></i>{storeSingle.contactDetails && storeSingle.contactDetails.mobile}</button>
              </Link>
              <button className='whatsapp-gallery' onClick={() => handleWhatsAppClick(storeSingle.businessDetails.businessName, storeSingle.contactDetails.whatsapp)}><i className="fa-brands fa-whatsapp"></i>whatsapp</button>
              {/* {
                login.permanent_ID === storeSingle.user_id ? <button className='add-images' ><i className="fa-solid fa-images"></i>add images</button> : null
              } */}
              <button className='share-gallery' onClick={() => handleShareClick(storeSingle.businessDetails.businessName, storeSingle.contactDetails.whatsapp, storeSingle.id)}><i className="fa-solid fa-share"></i></button>
              <button className='close-gallery' onClick={closeGallery}><i className="fa-solid fa-xmark"></i></button>
            </div>
          </div>

          <div className="gallery-images">
            {/* Left Side with Static Images */}
            <div className="gallery-left-side">
              {storeSingle?.formImages && storeSingle.formImages.map((image, index) => (
                <img
                  key={index}
                  src={`https://bigbullpaisewala.co.in/${image}`}
                  alt={`Gallery Image ${index + 1}`}
                  className={`gallery-left-img ${index === currentImage ? 'highlight-border' : ''}`} // Add conditional class
                  onClick={() => setCurrentImage(index)}
                />
              ))}
            </div>
            {/* Right Side with Carousel */}
            <div className="gallery-right-side">
              <div className="gallery-carousel">
                <button className="gallery-prev-btn" onClick={prevImage}>
                  <i className="fa-solid fa-chevron-left"></i>
                </button>
                <img src={`https://bigbullpaisewala.co.in/${storeSingle?.formImages?.[currentImage]}`} alt={`Gallery Carousel Image`} className="gallery-carousel-img" />
                <button className="gallery-next-btn" onClick={nextImage}>
                  <i className="fa-solid fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;
