import React from 'react';
import { Box, Text, Image, VStack } from '@chakra-ui/react';
import {Link, useNavigate} from 'react-router-dom'
import imges from "../Images/images.png"
import '../CSS/Global.css'

const NoProductFound = ({name}) => {
  const navigate=useNavigate()
  return (
    <>
      <div className="category-back-name">
      <div className="back-to-home" onClick={() => navigate(-1)}>
          <i className="fa-solid fa-arrow-left"></i> Back
        </div>
        <div className="category-single-name">
         {name}
        </div>
        <div></div>
      </div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="gray.50"
      >
        <VStack spacing={4} align="center">
          {/* Cloud Icon or Placeholder Image */}
          <Image
            marginTop={"30px"}
            src={imges} // Replace with your own cloud image URL
            alt="No Product Found"
            boxSize="150px"
          />

          {/* Sorry and Icon Section */}
          <Text fontSize="4xl" color="red.500" fontWeight="bold">
            <Box as="span" fontSize="5xl" color="red.600">
              !
            </Box>{' '}
            Sorry!
          </Text>

          {/* No Product Found Message */}
          <Text fontSize="2xl" color="gray.700" fontWeight="medium">
            No Listing Found...
          </Text>

          {/* Additional Suggestion */}
          <Text fontSize="md" color="gray.500">
            You can try our different listing...
          </Text>
        </VStack>
      </Box>
    </>
  );
};

export default NoProductFound;
