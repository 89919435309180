import '../CSS/Global.css'
import {Link} from 'react-router-dom'


const FooterLink = () =>{
    return(
        <>
        <div className="footer-link2">
            {/* <div className="footer-link-item2">
                <h3>Big Bull Paisawala</h3>
            </div> */}
            <div className="footer-link-item2">
                <Link to={`/terms-condition`}>Terms & condition</Link>
                <Link to={`/privacy-policy`}>Privacy policy</Link>
                <Link to={`/refund-policy`}>Refund policy</Link>
            </div>
        </div>
        </>
    );
};

export default FooterLink;