import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../Home";
import Wallet from "../Wallet";
import SearchPage from "../Search";
import Register from "../Register";
import Login from "../Login";
import Grocery from "../Grocery";
import Cosmetics from "../Cosmetics";
import StoreListing from "../BigBullForms/StoreListing";
import DetailListing from "../DetailPage";
import ChatUI from "../Message";
import UserProfile from "../UserProfile";
import VendorProfile from "../VendorProfile";
import ShowQR from "../ShowQR";
import VendorWallet from "../VendorWallet";
import Gallery from "../Gallery";
import AddProduct from "../Product";
import ListingForm from "../StoreListing";
import ProductListing from "../BigBullForms/ProductListingTable";
import AdvertiseListing from "../BigBullForms/AdvertiseTable";
import VendorListing from "../BigBullForms/VendorRegisterTable";
import UserListing from "../BigBullForms/UserRegisterTable";
import ScanQR from "../ScanQR";
import DetailPageVendor from "../DetailPageVendor";
import NoProductFound from "../NoProduct";
import CategoryProducts from "../BigBullWorld";
import BidBullWorld from "../People";
import SubCategory from "../Subcategory";
import Address from "../Address";
import Order from "../NewOrder";
import MyOrder from "../NewVendorOrder";
import Orderdesc from "../OrderDescription";
import MyAds from "../NewAds";
import EditProduct from "../EditProduct";
// import NewOrder from "../NewOrder";
import Track from "../Track";
import VendorOrder from "../VendorOrder";
import Invoice from "../Invoice";
import EditAddress from "../EditAddress";
import SuccessPage from '../SuccessPage'
import CategorySingle from "../CategorySingle";
import AllCategories from "../AllCategories";
import NotFoundPage from "../PageNotFound";
import SubCategoriesPage from "../SubCategoryPage";
import SetResetPassword from "../ResetPass";
import Thankyou from "../SuccessPage";
import ThankYouPage from "../ThankYou";

function AllRoute() {
    const login = JSON.parse(localStorage.getItem("login")) || {};

    // Define a component for restricted routes
    // const VendorRoute = ({ element, ...rest }) => {
    //     return login.type === "vendor" ? element : <Navigate to="/" />;
    // };
    const PrivateRoute = ({ element }) => {
        return login && login.type ? element : <Navigate to="/login" />;
    };

    // Vendor-Only Private Route
    const VendorRoute = ({ element }) => {
        return login && login.type === "vendor" ? element : <Navigate to="/" />;
    };

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/world" element={<PrivateRoute element={<CategoryProducts/>} />} />
            <Route path="/wallet" element={<PrivateRoute element={<Wallet />}/>} />
            <Route path="/world-diff" element={<PrivateRoute element={<BidBullWorld />}/>} />
            <Route path="/search" element={<PrivateRoute element={<SearchPage />}/>} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/:id" element={<PrivateRoute element={<Grocery />}/>} />
            <Route path="/sub/:category" element={<PrivateRoute element={<Cosmetics />}/>} />
            <Route path="/subcategory/:id" element={<PrivateRoute element={<SubCategory />}/>} />
            <Route path="/detail-listing/:id/:name" element={<PrivateRoute element={<DetailPageVendor />}/>} />
            <Route path="/message" element={<PrivateRoute element={<ChatUI />}/>} />
            <Route path="/success-page" element={<PrivateRoute element={<SuccessPage />}/>} />
            <Route path="/user-profile" element={<PrivateRoute element={<UserProfile />}/>} />
            <Route path="/gallery/:id/:name" element={<PrivateRoute element={<Gallery />}/>}  />
            <Route path="/empty" element={<NoProductFound />} />
            <Route path="/address" element={<PrivateRoute element={<Address />}/>} />
            <Route path="/single/:id" element={<PrivateRoute element={<EditAddress />}/>} />
            <Route path="/order" element={<PrivateRoute element={<Order />}/>} />
            <Route path="/order-description/:id" element={<PrivateRoute element={<Orderdesc />}/>} />
            <Route path="/ads" element={<PrivateRoute element={<MyAds />}/>} />
            <Route path="/showqr" element={<PrivateRoute element={<ShowQR />}/>} />
            <Route path="/vendor-order" element={<VendorRoute element={<MyOrder />} />} />
            <Route path="/vendor-table" element={<VendorRoute element={<VendorListing />} />} />
            <Route path="/product-table" element={<VendorRoute element={<ProductListing />} />} />
            <Route path="/store-listing" element={<VendorRoute element={<ListingForm />} />} />
            <Route path="/store-listing-table" element={<VendorRoute element={<StoreListing />} />} />
            <Route path="/product" element={<VendorRoute element={<AddProduct />} />} />
            <Route path="/edit-product/:id" element={<VendorRoute element={<EditProduct />} />} />
            <Route path="/vendor-wallet" element={<VendorRoute element={<VendorWallet />} />} />
            <Route path="/scanqr" element={<VendorRoute element={<ScanQR />} />} />
            <Route path="/vendor-profile" element={<VendorRoute element={<VendorProfile />} />} />
            <Route path="/ads-table" element={<AdvertiseListing />} />
            <Route path="/user-table" element={<UserListing />} />
            <Route path="/category-single" element={<CategorySingle />} />
            <Route path="/all-categories" element={<AllCategories />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="/sub-categories" element={<SubCategoriesPage />} />
            {/* <Route path="/new-order" element={<NewOrder />} /> */}
            <Route path="/track/:id" element={<PrivateRoute element={<Track />}/>} />
            <Route path="/vendortrack/:id" element={<VendorRoute element={<VendorOrder />}/>} />
            <Route path="/invoice/:id" element={<PrivateRoute element={<Invoice />}/>} />
            <Route path="/reset-password" element={<PrivateRoute element={<SetResetPassword />}/>} />
            <Route path="/order/thankyou/:id" element={<PrivateRoute element={<ThankYouPage />}/>} />
        </Routes>
    );
}

export default AllRoute;
